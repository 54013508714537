import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import UseCode from "routes/views/UseCode"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router-dom"
import NewCategoryList from "./NewCategoryList"
import CompanyCards from "./CompanyCards"
import { Button } from "antd"
import CompaniesFavorite from "./CompaniesFavorite"
import CompaniesSearch from "./CompaniesSearch"
import axios from "axios"
import { baseUrlImg, endpoint } from "../../../config/api"
import RedeemForm from "./RedeemForm"
import RedeemCard from "./RedeemCard"

const DashboardDomNew = (props) => {
  const {
    getCodiceTicket,
    accountInfo,
    match,
    services,
    setServices,
    getCategoryServices,
    getFavorites,
    subCategories,
    setSubCategories,
    favorites,
    servicesTree,
    getServicesTree,
  } = props

  const [hasVPT, setHasVPT] = useState(false)
  const [menuClassName, setMenuClassName] = useState("notFixed")
  const [categoryActive, setCategoryActive] = useState("")
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [hoveredCategory, setHoveredCategory] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [showIFrame, setShowIFrame] = useState(false)
  const [showRedeem, setShowRedeem] = useState(false)

  const id = match.params.id
  const selectedCategory = servicesTree.find(
    (category) => category.slug && category.slug.split("/").pop() === id
  )

  const setFixedMenu = () => {
    const catInst = document.querySelector(".Dashboard > .Categories")
    if (catInst) {
      catInst.style.height =
        catInst.getBoundingClientRect().height -
        (catInst.getBoundingClientRect().height +
          186 -
          document.querySelector("footer").getBoundingClientRect().y) +
        "px"
    }

    const scrollPoint = document
      .querySelector("#SpecStatistich")
      ?.classList?.contains("min")
      ? 386
      : 486
    const top = window.scrollY || document.documentElement.scrollTop
    const newMenuClassName = top >= scrollPoint ? "fixed" : "notFixed"

    if (menuClassName !== newMenuClassName) {
      setMenuClassName(newMenuClassName)
    }
  }

  const handleScroll = () => {
    if (accountInfo?.token && match.params.id !== "caffe_mamanero") {
      setFixedMenu()
    }
  }

  const ChangeCompanies = (CategoryType) => {
    setCategoryActive(CategoryType)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [accountInfo, match])

  useEffect(() => {
    getServicesTree()
  }, [])

  useEffect(() => {
    if (
      selectedCategory &&
      selectedCategory.children_recursive.length > 0 &&
      !categoryActive
    ) {
      const firstCategory = selectedCategory.children_recursive[0]
      setCategoryActive(firstCategory)
      getCategoryServices(firstCategory.id)
      setShowRedeem(false)
    }
  }, [selectedCategory, categoryActive, getCategoryServices, setShowRedeem])

  useEffect(() => {
    setServices(null)
    setSubCategories(null)
    setCategoryActive("")
  }, [id])

  useEffect(() => {
    getFavorites()
  }, [])

  useEffect(() => {
    if (searchValue.length === 0) {
      if (subCategories && subCategories.length > 0) {
        const firstSubCategory = subCategories[0]
        setActiveSubCategory(firstSubCategory.id)
        getCategoryServices(firstSubCategory.id)
      }
    }
  }, [subCategories, searchValue])

  const handleSubCategoryClick = (sub) => {
    setActiveSubCategory(sub.id)
    getCategoryServices(sub.id)
  }

  const onChange = (e) => {
    setSearchValue(e.target.value)
  }

  const filterCompanies = () => {
    const token = accountInfo?.token

    axios
      .get(`${endpoint}/category/companies/search?query=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setServices(res.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (searchValue.length > 0) {
      filterCompanies()
    } else {
      if (categoryActive && categoryActive.id !== "redeem") {
        if (categoryActive.children_recursive.length > 0) {
          setSubCategories(categoryActive.children_recursive)
        } else {
          setSubCategories(null)
          getCategoryServices(categoryActive.id)
        }
      }
    }
  }, [searchValue, categoryActive, subCategories])

  useEffect(() => {
    setShowIFrame(false)
  }, [categoryActive])


  return showIFrame ? (
    <iframe
      src="https://app.mrpay.it/codice-fiscale/registrati"
      width="100%"
      style={{ height: "calc(100vh - 445px)" }}
    />
  ) : (
    <>
      {/* {showRedeem && selectedCategory.id === 61 && (
        <RedeemForm
          onClose={() => {
            setShowRedeem(false)
            setCategoryActive(selectedCategory.children_recursive[0])
          }}
        />
      )} */}
      <div className="DContainer maxWidth">
        {hasVPT && (
          <div
            className="backDrop"
            onClick={() => {
              setHasVPT(false)
            }}
          >
            <UseCode
              getCodiceTicket={getCodiceTicket}
              paymentNotFinished={true}
              prenotationType="PrenotedBollettini"
            />
          </div>
        )}

        <div className={`Dashboard notFixed`}>
          {/* <NewCategoryList
          menuClassName={menuClassName}
          activeCategory={categoryActive.id}
          onClickCategory={ChangeCompanies}
          setShowRedeem={setShowRedeem}
        /> */}
          <div className={`CompaniesAndOther notFixed`}>
            {/* <CompaniesFavorite favourites={favorites} /> */}

            <div className="Comp">
              <CompaniesSearch onChange={onChange} />
              {selectedCategory &&
                selectedCategory.children_recursive.length > 1 && (
                  <div className="subcategories">
                    {selectedCategory.children_recursive &&
                      selectedCategory.children_recursive.length > 1 &&
                      selectedCategory.children_recursive.map((sub) => (
                        <div
                          key={sub.id}
                          className={`category-item ${
                            categoryActive.id === sub.id ? "active" : ""
                          }`}
                          onMouseEnter={() => setHoveredCategory(sub.id)}
                          onMouseLeave={() => setHoveredCategory(null)}
                          onClick={() => setCategoryActive(sub)}
                        >
                          {(hoveredCategory === sub.id ||
                            categoryActive.id === sub.id) && (
                            <div className="tooltip">{sub.name}</div>
                          )}
                          <img
                            src={
                              sub.image || `${baseUrlImg}/icon/icon-${sub.id}.svg`
                            }
                            alt={sub.name}
                            className="category-image"
                          />
                        </div>
                      ))}
                  </div>
                )}

              <div className="telefoniche-options">
                {subCategories &&
                  subCategories.length > 0 &&
                  subCategories.map((sub) => (
                    <Button
                      key={sub.id}
                      onClick={() => handleSubCategoryClick(sub)}
                      className={activeSubCategory === sub.id ? "active" : ""}
                    >
                      {sub.name}
                    </Button>
                  ))}
              </div>
              {services && services.length > 0 ? (
                <>
                  <div className="Companies">
                    <CompanyCards
                      companies={services.filter(
                        (service) => service.name !== "MAV"
                      )}
                      categoryActive={categoryActive.id}
                      activeSubCategory={activeSubCategory}
                      setShowIFrame={setShowIFrame}
                    />
                    {selectedCategory && selectedCategory.id === 61 && (
                      <RedeemCard setShowRedeem={setShowRedeem} />
                    )}
                  </div>
                </>
              ) : (
                <p>No companies found for this category.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapsStateToProps = (state) => ({
  favorites: state.main.favorites,
  services: state.main.services,
  accountInfo: state.auth.accountInfo,
  scannedBarcode: state.auth.scannedBarcode,
  skinExtras: state.auth.skinExtras,
  subCategories: state.main.subCategories,
  servicesTree: state.main.servicesTree,
})

export default withRouter(
  connect(mapsStateToProps, { ...MainActions, ...AuthActions })(DashboardDomNew)
)
