import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import { withRouter } from "react-router"
import { Button, Collapse } from "antd"
import CompanyCards from "../../../routes/domains/Dashboard/CompanyCards"
import { baseUrlImg } from "../../../config/api"
import Favourites from "./Favourites"

const { Panel } = Collapse

const NewServizi = ({
  getServicesTree,
  servicesTree,
  getCategoryServices,
  services,
  setCompanyServices,
  setSubCategories,
  subCategories,
  tab,
  setTab,
  activeTab,
}) => {
  const [expandedKeys, setExpandedKeys] = useState()
  const [activeSubCategory, setActiveSubCategory] = useState(null)
  const [categoryActive, setCategoryActive] = useState(null)

  useEffect(() => {
    getServicesTree()
  }, [])

  useEffect(() => {
    if (activeTab) {
      setTab(activeTab)
    }
  }, [activeTab])

  useEffect(() => {
    setSubCategories(null)
  }, [tab])

  useEffect(() => {
    if (servicesTree && servicesTree.length > 0) {
      const filteredTree = servicesTree.filter(
        (category) => category.name !== "Uncategorized"
      )

      if (filteredTree.length > 0) {
        const firstCategory = filteredTree[0]
        setExpandedKeys([String(firstCategory.id)])

        if (firstCategory.children_recursive?.length > 0) {
          const firstChild = firstCategory.children_recursive[0]
          setCategoryActive(firstChild)
          setSubCategories(firstChild.children_recursive || [])
        } else {
          setCategoryActive(firstCategory)
          setSubCategories([])
        }
      }
    }
  }, [servicesTree])

  useEffect(() => {
    if (servicesTree && categoryActive && tab !== "fav") {
      if (
        categoryActive.children_recursive &&
        categoryActive.children_recursive.length > 0
      ) {
        setSubCategories(categoryActive.children_recursive)
      } else {
        getCategoryServices(categoryActive.id)
      }
    }
    if (subCategories && subCategories.length > 0) {
      const firstSubCategory = subCategories[0]
      setActiveSubCategory(firstSubCategory.id)

      getCategoryServices(firstSubCategory.id)
    }
  }, [subCategories, categoryActive])

  useEffect(() => {
    if (tab) {
      const category = findCategoryById(tab, servicesTree)

      if (category) {
        setExpandedKeys(tab)

        if (category.children_recursive && category.children_recursive.length > 0) {
          setCategoryActive(category.children_recursive[0])
        } else {
          setCategoryActive(category)
        }
      } else {
        setCompanyServices([])
      }
    } else {
      setCompanyServices([])
    }
  }, [tab, servicesTree])

  const handleSubCategoryClick = (sub) => {
    setActiveSubCategory(sub.id)

    getCategoryServices(sub.id)
  }

  const onPanelChange = (keys) => {
    const category = findCategoryById(keys[keys.length - 1], servicesTree)
    setExpandedKeys(keys[keys.length - 1])

    setSubCategories(null)

    if (keys.length !== 0) {
      if (category.children_recursive.length > 0) {
        setCategoryActive(category.children_recursive[0])
      } else {
        setCategoryActive(category)
      }
    } else {
      setCompanyServices([])
    }
  }

  const findCategoryById = (id, services) => {
    for (let service of services) {
      if (service.id == id) {
        return service
      }

      if (service.children_recursive && service.children_recursive.length > 0) {
        const foundInChildren = findCategoryById(id, service.children_recursive)
        if (foundInChildren) {
          return foundInChildren
        }
      }
    }

    return null
  }

  const filteredServices =
    tab != 0
      ? servicesTree?.filter(
          (service, index, self) =>
            service.parent_id === null &&
            service.name !== "Uncategorized" &&
            index === self.findIndex((s) => s.name === service.name) &&
            service.id == tab
        )
      : servicesTree?.filter(
          (service, index, self) =>
            service.parent_id === null &&
            service.name !== "Uncategorized" &&
            index === self.findIndex((s) => s.name === service.name)
        )

  return tab === "fav" ? (
    <Favourites />
  ) : (
    <Collapse
      defaultActiveKey={["1"]}
      onChange={onPanelChange}
      activeKey={expandedKeys}
    >
      {filteredServices.map((service) => {
        return (
          <Panel header={service.name} key={service.id}>
            {service.children_recursive && service.children_recursive.length > 1 && (
              <div className="subcategories">
                {service.children_recursive.map((sub) => (
                  <div
                    key={sub.id}
                    className={`category-item ${
                      categoryActive?.id === sub.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setCategoryActive(sub)
                      setSubCategories(null)
                    }}
                  >
                    {categoryActive?.id === sub.id && (
                      <div className="tooltip">{sub.name}</div>
                    )}
                    <img
                      src={sub.image || `${baseUrlImg}/icon/icon-${sub.id}.svg`}
                      alt={sub.name}
                      className="category-image"
                    />
                  </div>
                ))}
              </div>
            )}

            {services && services.length > 0 ? (
              <div>
                <div
                  className="telefoniche-options"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 10,
                    gap: 10,
                  }}
                >
                  {subCategories &&
                    subCategories.length > 0 &&
                    subCategories.map((sub) => (
                      <Button
                        key={sub.id}
                        onClick={() => handleSubCategoryClick(sub)}
                        className={activeSubCategory === sub.id ? "active" : ""}
                      >
                        {sub.name}
                      </Button>
                    ))}
                </div>
                <div className="mobileServices--body">
                  <CompanyCards
                    companies={services.filter((service) => service.name !== "MAV")}
                  />
                </div>
              </div>
            ) : (
              <p>No companies found for this category.</p>
            )}
          </Panel>
        )
      })}
    </Collapse>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
  services: state.main.services,
  subCategories: state.main.subCategories,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewServizi)
)
