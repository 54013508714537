import React, { Component } from "react"
import { connect } from "react-redux"
import { Form, Input, Button, Row, Col, DatePicker, Icon, Select } from "antd"
import { AuthActions, MainActions } from "redux-store/models"
import { withRouter } from "react-router"
import moment from "moment"
import { docType } from "../../config"

const { Option } = Select

class EditUser extends Component {
  state = {
    skinSelected: "",
    agentSelected: "",
    loading: false,
  }
  resetState = async (id) => {
    const role = this.props.userDetail.role
    if (role === "user") {
      await this.props.getUserByUserId(id)
    } else if (role === "agency") {
      await this.props.getUserDetail(id)
    } else if (role === "agent") {
      await this.props.getAgentByUserId(id)
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    const { form, userDetail, activeSkinId, updateUserDetail } = this.props
    const { loading } = this.state
    form.validateFields((err, values) => {
      if (!err) {
        console.log("Submitted values:", values)
        updateUserDetail(
          userDetail.id,
          values.phone,
          values.document_type,
          values.document_number,
          values.rilasciato_da,
          values.luogo_di_rilascio,
          values.data_di_rilascio,
          values.data_di_scadenza,
          values.insegna,
          values.cordinate,
          values.a_phone,
          values.sede_operativa || values.a_address,
          values.comune || values.a_city,
          values.provincia,
          values.cap,
          values.nazione,
          values.pagamento_mensile,
          values.password,
          values.confirm_password,
          activeSkinId,
          this.resetState,
          {
            username: values.username,
            email: values.email,
            a_ragione_sociale: values.ragione_sociale || values.a_ragione_sociale,
            a_iva: values.p_iva,
            first_name: values.first_name,
            last_name: values.last_name,
            birth_comune_code: values.birth_comune_code,
            birth_country: values.birth_country,
            birth_place: values.birth_place,
            birthday: values.birthday,
            city: values.city || values.a_city,
            gender: values.gender,
            personal_number: values.personal_number,
            ragione_sociale: values.ragione_sociale,
            p_iva: values.p_iva,
            country: values.country,
            address: values.address,
            cap: values.cap,
            comune_code: values.comune_code,
          },
          (loadingState) => {
            this.setState({ loading: loadingState })
          }
        )
      }
    })
  }

  render() {
    const { loading } = this.state
    const {
      form,
      userDetail,
      history,
      activeSkinId,
      agents,
      skinList,
      setUserDetail,
    } = this.props
    const { getFieldDecorator } = form

    console.log(loading)
    console.log(skinList)
    const agencyFields = [
      { key: "ragione_sociale", label: "Ragione Sociale" },
      { key: "username", label: "Username" },
      { key: "insegna", label: "Insegna" },
      { key: "password", label: "Password" },
      { key: "cordinate", label: "Coordinate" },
      { key: "confirm_password", label: "Conferma Password" },
      { key: "a_phone", label: "Telefono Agenzia" },
      { key: "phone", label: "Cellulare" },
      { key: "sede_operativa", label: "Sede Operativa" },
      { key: "document_type", label: "Tipo Documento", type: "document" },
      { key: "comune", label: "Comune" },
      { key: "cap", label: "Cap" },
      { key: "provincia", label: "Provincia di residenza" },
      { key: "nazione", label: "Nazione di residenza" },
      { key: "p_iva", label: "P.Iva" },
      { key: "pagamento_mensile", label: "Pagamento Mensile" },
      { key: "email", label: "Email" },
      { key: "rilasciato_da", label: "Rilasciato da", type: "rilasciato" },
      { key: "document_number", label: "Numero Documento" },
      { key: "luogo_di_rilascio", label: "Luogo di rilascio" },
      { key: "data_di_rilascio", label: "Data di rilascio", type: "date" },
      { key: "data_di_scadenza", label: "Data di scadenza", type: "date" },
    ]

    const agentFields = [
      { key: "ragione_sociale", label: "Ragione Sociale" },
      { key: "username", label: "Soprannome" },
      { key: "a_address", label: "Address" },
      { key: "personal_number", label: "Personal Number" },
      { key: "birth_comune_code", label: "Codice comune di nascita" },
      { key: "password", label: "Password" },
      { key: "first_name", label: "First Name" },
      { key: "confirm_password", label: "Conferma Password" },
      { key: "last_name", label: "Last Name" },
      { key: "document_type", label: "Tipo Documento", type: "document" },
      { key: "birth_country", label: "Nazione" },
      { key: "phone", label: "Cellulare" },
      { key: "birth_place", label: "Provincia di residenza" },
      { key: "document_number", label: "Numero Documento" },
      { key: "birthday", label: "Compleanno", type: "date" },
      { key: "cap", label: "Cap" },
      { key: "a_city", label: "Citta" },
      { key: "country", label: "Nazione di residenza" },
      { key: "p_iva", label: "P.Iva" },
      { key: "gender", label: "Gender", type: "gender" },
      { key: "luogo_di_rilascio", label: "Luogo di rilascio" },
      { key: "rilasciato_da", label: "Rilasciato da", type: "rilasciato" },
      { key: "email", label: "Email" },
      { key: "data_di_rilascio", label: "Data di rilascio", type: "date" },
      { key: "data_di_scadenza", label: "Data di scadenza", type: "date" },
    ]

    const userFields = [
      { key: "address", label: "Address" },
      { key: "username", label: "Soprannome" },
      { key: "birth_country", label: "Nazione di residenza" },
      { key: "birth_place", label: "Provincia di residenza" },
      { key: "birth_comune_code", label: "Codice comune di nascita" },
      { key: "phone", label: "Cellulare" },
      { key: "birthday", label: "Compleanno", type: "date" },
      { key: "password", label: "Password" },
      { key: "cap", label: "Cap" },
      { key: "confirm_password", label: "Conferma Password" },
      { key: "first_name", label: "First Name" },
      { key: "city", label: "Citta" },
      { key: "last_name", label: "Last Name" },
      { key: "country", label: "Nazione" },
      { key: "comune_code", label: "Code comune" },
      { key: "document_type", label: "Tipo Documento", type: "document" },
      { key: "personal_number", label: "Personal Number" },
      { key: "document_number", label: "Numero Documento" },
      { key: "luogo_di_rilascio", label: "Luogo di rilascio" },
      { key: "gender", label: "Gender", type: "gender" },
      { key: "email", label: "Email" },
      { key: "rilasciato_da", label: "Rilasciato da", type: "rilasciato" },
      { key: "data_di_rilascio", label: "Data di rilascio", type: "date" },
      { key: "data_di_scadenza", label: "Data di scadenza", type: "date" },
    ]

    const formMapping =
      userDetail?.role === "agent"
        ? agentFields
        : userDetail?.role === "user"
        ? userFields
        : agencyFields

    console.log(userDetail)
    if (userDetail)
      return (
        <div className="edit-user-form add-service-form">
          <div className="title">
            {" "}
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <Icon type="user" />
              <h5 onClick={() => history.push(`/back-office/utenti`)}>
                {`Utenti / ${userDetail.username}`}
              </h5>
            </div>
          </div>

          <Form onSubmit={this.handleFormSubmit}>
            {userDetail && Object.keys(userDetail).length > 0 && (
              <span className="form-header">
                {activeSkinId === -1
                  ? "Dati Cliente"
                  : ` Modifica dati ${
                      userDetail.role === "agency" ? "Agenzia" : userDetail.role
                    }`}
              </span>
            )}

            <Row gutter={16}>
              {/* Form Fields Mapping */}

              {formMapping.map(({ key, label, type }) => (
                <Col xs={24} sm={12} key={key}>
                  <Form.Item label={label}>
                    {getFieldDecorator(key, {
                      initialValue:
                        type === "date"
                          ? moment(userDetail[key])
                          : type === "document"
                          ? userDetail?.document_type?.toString()
                          : type === "gender"
                          ? userDetail?.gender
                          : type === "rilasciato"
                          ? userDetail?.rilasciato_da?.toString()
                          : userDetail[key]?.toString() || null,
                      rules: [{ required: false, message: `${label} is required` }],
                    })(
                      type === "date" ? (
                        <DatePicker
                          format="DD/MM/YYYY"
                          placeholder={`Select ${label}`}
                        />
                      ) : type === "document" ? (
                        <Select placeholder="Tipo documento">
                          {docType.map((doc) => (
                            <Option key={doc.id} value={doc.id}>
                              {doc.name}
                            </Option>
                          ))}
                        </Select>
                      ) : type === "gender" ? (
                        <Select placeholder="Gender">
                          <Option value="F">Donna</Option>
                          <Option value="M">Uomo</Option>
                        </Select>
                      ) : type === "rilasciato" ? (
                        <Select placeholder="Rilasciato da">
                          <Option value="1">Comune</Option>
                          <Option value="10">Motorizzazione</Option>
                          <Option value="13">Questura</Option>
                          <Option value="14">Polizia</Option>
                          <Option value="16">Commissariato</Option>
                          <Option value="19">Altro</Option>
                        </Select>
                      ) : (
                        <Input />
                      )
                    )}
                  </Form.Item>
                </Col>
              ))}

              {/* Conditional Rendering for Agent Selection (for agency role) */}
              {userDetail.role === "agency" && agents && (
                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                  <div className="form-item">
                    <label className="form-label">Cambia Agente</label>
                    <div className="agent-selection-container">
                      <Select
                        defaultValue={userDetail.agent_id}
                        onChange={(e) => this.setState({ agentSelected: e })}
                        className="agent-select"
                      >
                        {(this.props.agents || []).map((agent, id) => (
                          <Option key={id} value={agent.id}>
                            {agent.first_name} {agent.last_name} [
                            {`${agent.username}`}]
                          </Option>
                        ))}
                      </Select>
                      <button
                        type="button"
                        className="confirm-button"
                        onClick={async () => {
                          const skinId = this.props.activeSkinId
                          await this.props.changeAgent(
                            this.state.agentSelected,
                            userDetail.id,
                            skinId
                          )
                        }}
                      >
                        <i className="fal fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              )}

              {/* Conditional Rendering for Skin Selection (for agent role) */}
              {userDetail.role === "agent" && skinList && (
                <Col xs={24} sm={12} style={{ paddingBottom: 8 }}>
                  <div className="form-item">
                    <label className="form-label">Cambia Skin</label>
                    <div className="agent-selection-container">
                      <Select
                        defaultValue={userDetail.skin_id}
                        onChange={(e) => this.setState({ skinSelected: e })}
                        className="agent-select"
                      >
                        {(this.props.skinList || []).map((skin, id) => (
                          <Option key={id} value={skin.id}>
                            {`${skin.username}`}
                          </Option>
                        ))}
                      </Select>
                      <button
                        type="button"
                        className="confirm-button"
                        onClick={async () => {
                          this.props.changeAgentSkin(
                            userDetail.id,
                            this.state.skinSelected
                          )
                        }}
                      >
                        <i className="fal fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </Col>
              )}

              {/* Buttons Section */}
              <Col
                xs={24}
                sm={12}
                style={{ paddingRight: 0, paddingBottom: 30, marginTop: 22 }}
              >
                <Row
                  gutter={16}
                  justify="start"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Col style={{ flex: 1, paddingBottom: 8 }}>
                    <Button
                      onClick={() => {
                        history.push(`/back-office/utenti`)
                        setUserDetail(null)
                      }}
                      type="default"
                      style={{ width: "100%" }}
                    >
                      Annulla
                    </Button>
                  </Col>
                  <Col style={{ flex: 1, paddingBottom: 8 }}>
                    {userDetail && Object.keys(userDetail).length > 0 && (
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          backgroundColor: "var(--accent-bg)",
                          borderColor: "var(--accent-bg)",
                        }}
                      >
                        Salva
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )
    else return <div className="loaderAdmin">Loading...</div>
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.auth.userDetail,
  accountInfo: state.auth.accountInfo,
  agents: state.auth.agents,
  skinList: state.auth.skinList,
})

const WrappedEditUser = Form.create()(EditUser)

export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  withRouter(WrappedEditUser)
)
