import React, { Fragment, useEffect, useState } from "react"
import { Header } from "../../shared-components"
import { Button, Divider, Form, Input, message, Steps } from "antd"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getCopy } from "utils"
import { AuthActions, MainActions } from "redux-store/models"
import { InlineShareButtons } from "sharethis-reactjs"
import Step1 from "../../shared-components/RegisterEndUser/Step1"
import Step2 from "../../shared-components/RegisterEndUser/Step2"
import Step3 from "../../shared-components/RegisterEndUser/Step3"

const { Step } = Steps

const NewRegisterEndUser = (props) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [token, setToken] = useState({})
  const [formData, setFormData] = useState({
    sexKey: "",
    codice_fiscale: "",
    gender: "F",
    nazione: "ITALIA",
    card_view: "1",
  })
  const [shareIcons, setShareIcons] = useState(false)
  const [selectedRegisterType, setSelectedRegisterType] = useState("")
  const [loading, setLoading] = useState(false)
  const { form, getRegister, getRegisterToken, match, accountInfo } = props

  const url =
    selectedRegisterType === "complete"
      ? `${window.location.href}t/${token?.register_token}`
      : `${window.location.origin}/#/register-user-token/${token?.register_token}`

  useEffect(() => {
    if (match.params.token) {
      setToken(match.params.token)
    } else {
      getRegisterToken(accountInfo.profile.account_id, setToken)
    }
  }, [match.params.token, getRegisterToken, accountInfo])

  const formatDate = (date) => {
    const { day, month, year } = date
    return `${year}-${month}-${day}`
  }
  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData((prevData) => ({
          ...prevData,
          ...values,
        }))
        setCurrentStep(currentStep + 1)
      })
      .catch(() => {
        message.error("Compilare tutti i campi richiesti.")
      })
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const finalData = {
          ...formData,
          ...values,
        }
        setFormData(finalData)
        getRegister(
          finalData.first_name,
          finalData.last_name,
          finalData.nickname,
          finalData.email,
          finalData.gender,
          finalData.codice_fiscale,
          formatDate(finalData.birthday),
          finalData.nazione,
          finalData.province_of_birth.value,
          finalData.city_of_birth.value,
          finalData.nazioneDiResidenca,
          finalData.residence_province,
          finalData.residence_city,
          finalData.address,
          finalData.cap,
          finalData.identity_id,
          finalData.identity_type,
          finalData.number_prefix || "+39",
          finalData.number,
          finalData.imageUrl,
          finalData.imageUrl2,
          "user",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          finalData.confirm_password,
          finalData.password,
          finalData.rilasciato_da,
          finalData.luogo_di_rilascio,
          formatDate(finalData.data_di_rilascio),
          formatDate(finalData.data_di_scadenza),
          "",
          "",
          "",
          1,
          finalData.recieve_emails,
          "",
          match.params.token,
          "",
          "",
          "",
          "",
          accountInfo?.profile?.account_id || "",
          setLoading,
          form.resetFields
        )
      })

      // form.resetFields()
      .catch(() => {
        message.error("Compilare tutti i campi richiesti.")
      })
  }

  const handleStepChange = (nextStep) => {
    if (nextStep > currentStep) {
      form
        .validateFields()
        .then((values) => {
          setFormData((prevData) => ({
            ...prevData,
            ...values,
          }))
          setCurrentStep(nextStep)
        })
        .catch(() => {
          message.error("Compilare tutti i campi richiesti.")
        })
    } else {
      setCurrentStep(nextStep)
    }
  }

  return (
    <Fragment>
      <Header />
      <div
        style={{
          marginTop: 20,
          maxWidth: "530px",
          margin: "30px auto",
          cursor: "pointer",
          fontSize: 24,
        }}
      >
        Registra Utente
      </div>
      <div
        style={{
          marginTop: 20,
          maxWidth: "530px",
          margin: "30px auto",
          marginBottom: 0,
          cursor: "pointer",
        }}
      >
        <Steps
          current={currentStep}
          className="regUser-step"
          style={{ color: "var(--accent-bg)" }}
        >
          <Step
            title={<span className="step-title">Informazioni personali</span>}
            onClick={() => handleStepChange(0)}
          />
          <Step title="Indirizzo residenza" onClick={() => handleStepChange(1)} />
          <Step title="Dati login" onClick={() => handleStepChange(2)} />
        </Steps>
      </div>
      <Divider className="reg-divider" />

      <Form
        layout="vertical"
        className="newRegistration newReg"
        style={{ marginTop: 20, maxWidth: "530px" }}
      >
        {/* Step 1: Personal Information */}
        {currentStep === 0 && (
          <Step1 form={form} formData={formData} setFormData={setFormData} />
        )}

        {/* Step 2: Birth and Residence */}
        {currentStep === 1 && (
          <Step2 form={form} formData={formData} setFormData={setFormData} />
        )}

        {/* Step 3: Document Information */}
        {currentStep === 2 && (
          <Step3 form={form} setFormData={setFormData} formData={formData} />
        )}

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {currentStep === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                style={{ height: "40px", width: "150px", marginRight: 10 }}
                onClick={handleNext}
              >
                Procedi
              </Button>

              {!match.params.token && (
                <div className="shareRegister">
                  <div
                    onClick={() => {
                      setShareIcons(!shareIcons)
                      setSelectedRegisterType("")
                    }}
                  >
                    <i className="fal fa-share-nodes" aria-hidden="true"></i>
                    Condividere
                  </div>
                  {shareIcons && (
                    <div className="overlay" onClick={() => setShareIcons(false)}>
                      <div
                        className="options-btns"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {selectedRegisterType ? (
                          <>
                            <i
                              className="fal fa-copy"
                              onClick={() => {
                                getCopy(url)
                              }}
                            ></i>

                            <InlineShareButtons
                              config={{
                                alignment: "center",
                                color: "social",
                                enabled: true,
                                font_size: 16,
                                language: "en",
                                url: url,
                                networks: ["whatsapp", "telegram", "email"],
                                padding: 12,
                                radius: 4,
                                show_total: true,
                                size: 40,
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Button
                              type="primary"
                              className="simple-reg-btn"
                              onClick={() => setSelectedRegisterType("simple")}
                            >
                              Registrazione Semplice
                            </Button>
                            <Button
                              type="primary"
                              className="simple-reg-btn"
                              onClick={() => setSelectedRegisterType("complete")}
                            >
                              Registrazione Completa
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {/* {
                    <div
                      className={"options" + (shareIcons ? "" : " d-none")}
                      onClick={() => history.push("/register-user")}
                    >
                      <Button type="primary" className="simple-reg-btn">
                        Registrazione semplice
                      </Button>

                      <i
                        className="fal fa-copy"
                        onClick={() => {
                          getCopy(
                            `${window.location.href}t/${token?.register_token}`
                          )
                        }}
                      ></i>

                      <InlineShareButtons
                        config={{
                          alignment: "center",
                          color: "social",
                          enabled: true,
                          font_size: 16,
                          language: "en",
                          url: `${window.location.href}t/${token?.register_token}`,
                          networks: [
                            // which networks to include (see SHARING NETWORKS)
                            "whatsapp",
                            "telegram",
                            "email",
                          ],
                          padding: 12,
                          radius: 4,
                          show_total: true,
                          size: 40,
                        }}
                      />
                    </div>
                  } */}
                </div>
              )}
            </div>
          )}
          {currentStep === 1 && (
            <Button type="primary" onClick={handleNext} block>
              Procedi
            </Button>
          )}
          {currentStep === 2 && (
            <Button type="primary" onClick={handleSubmit} block loading={loading}>
              Registrati
            </Button>
          )}
        </div>
      </Form>
    </Fragment>
  )
}

const InfoUser = Form.create({ name: "infoUser" })(NewRegisterEndUser)

const mapsStateToProps = ({ auth, main }) => ({
  personalInfo: auth.personalInfo,
  register: auth.register,
  accountInfo: auth.accountInfo,
  screenWidth: main.screenWidth,
  skinExtras: auth.skinExtras,
})

export default withRouter(
  connect(mapsStateToProps, { ...AuthActions, ...MainActions })(InfoUser)
)
