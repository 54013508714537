import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Popover,
  Select,
  Switch,
  Table,
  TreeSelect,
  notification,
} from "antd"
import images from "themes/images"
import ContiGiocoModal from "../../shared-components/adminSharedComp/adminModals/ContiGiocoModal/ContiGiocoModal"
import AdminSubServizzi from "../domains/adminPanel/AdminSubServizzi/AdminSubServizzi"
import { withRouter } from "react-router"
import AddServiceModalForm from "../../shared-components/adminSharedComp/adminModals/AddServiceModal/AddServiceModal"
import { useParams } from "react-router-dom"
import { baseUrlImg } from "../../config/api"

const { Option } = Select

const NewServicesAdmin = (props) => {
  const {
    activeSkinId,
    screenWidth,
    MessaggiComponent,
    getAllServices,
    allServices,
    servicesLoader,
    getSelectedCompany,
    getContiAccount,
    setContiAccount,
    setNewContiStatus,
    UpdateServicesChangeStatus,
    changeServiceCategory,
    setActiveSkinId,
    setActiveSkinName,
    skinList,
    history,
    getContiGiocoServices,
    updateServiceName,
    getProviders,
    accountInfo,
    providers,
    getServicesTree,
    servicesTree,
    setSelectedCompany,
  } = props

  const { provider } = useParams()

  const [selectedSupplier, setSelectedSupplier] = useState(provider || "MrPay")
  const [filterName, setFilterName] = useState("")
  const [activeCategory, setActiveCategory] = useState("TUTTI")
  const [openModal, setOpenModal] = useState(false)
  const [service, setService] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [editedName, setEditedName] = useState("")
  const [loading, setLoading] = useState(false)
  const [focusedRow, setFocusedRow] = useState(null)
  const [blurRow, setBlurRow] = useState(null)
  const [addServiceModalVisible, setAddServiceModalVisible] = useState(false)

  const [visibleColumns, setVisibleColumns] = useState(() => {
    const savedColumns = localStorage.getItem("visibleColumns")
    return savedColumns
      ? JSON.parse(savedColumns)
      : {
          "Nome Servizio": true,
          Category: true,
          Impostazioni: true,
          Icon: false,
          Logo: false,
          Scontrino: false,
          Background: false,
          Agenzia: false,
          "Aggio Skin": false,
          "Aggio Bruto": false,
          Impostazioni: true,
        }
  })

  useEffect(() => {
    localStorage.setItem("visibleColumns", JSON.stringify(visibleColumns))
  }, [visibleColumns])

  useEffect(() => {
    if (skinList?.length > 0) {
      const firstSkin = skinList[0]
      if (activeSkinId === -1) {
        setActiveSkinId(firstSkin.id)
        setActiveSkinName(firstSkin.username)
        getAllServices(
          firstSkin.id,
          selectedSupplier !== "TUTTI" ? selectedSupplier : null
        )
      } else {
        getAllServices(
          activeSkinId,
          selectedSupplier !== "TUTTI" ? selectedSupplier : null
        )
      }
    }
  }, [activeSkinId, skinList])

  useEffect(() => {
    getContiGiocoServices()
    getServicesTree()
    getProviders()
    history.push(`/back-office/servizi/${selectedSupplier}`)
  }, [])

  const handleUpdateServiceName = (id) => {
    updateServiceName(id, editedName, setLoading)
    setEditedName("")
  }

  const handleCheckboxChange = (e, columnKey) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: e.target.checked,
    }))
  }

  const formatNumber = (val) => {
    const number = parseFloat(val)
    return Number.isInteger(number) ? number : number.toFixed(2)
  }

  const handleSettingsClick = async (record) => {
    if (record.category === "Visure") {
      window.location.href = `${window.origin + window.location.hash}/${record.id}`
    } else {
      await getSelectedCompany(record.id)
      window.location.href = `${window.origin + window.location.hash}/edit/${
        record.id
      }`
    }
  }

  const transformToTreeData = (categories) => {
    return categories
      .filter((category) => category.name !== "Uncategorized")
      .map((category) => ({
        title: category.name,
        value: category.id.toString(),
        children: transformToTreeData(category.children_recursive),
      }))
  }

  const columns =
    screenWidth < 500
      ? [
          {
            title: "Nome servizio",
            dataIndex: "full_name",
            key: "full_name",
            ellipsis: true,
            width: "50%",
          },
          {
            title: "Provvigione",
            dataIndex: "provvigione",
            key: "provvigione",
            ellipsis: true,
            align: "right",
            width: "25%",
            render: (text) => `${text}€`,
          },
          {
            title: "Impostazioni",
            dataIndex: "impostazioni",
            key: "impostazioni",
            width: "25%",
            ellipsis: true,
            align: "center",
            render: (text, record) => (
              <div className="icons">
                <i
                  className="fal fa-cog"
                  onClick={() => handleSettingsClick(record)}
                />
              </div>
            ),
          },
        ]
      : [
          {
            title: "ID",
            dataIndex: "company_id",
            key: "company_id",
            ellipsis: true,
            width: "6%",
          },

          visibleColumns["Nome Servizio"] && {
            title: () => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <span>Nome servizio</span>
                  <i
                    className="fa-regular fa-pen-to-square"
                    style={{ position: "absolute", right: "0px", cursor: "pointer" }}
                    onClick={() => setIsEditing(!isEditing)}
                  ></i>
                </div>
              )
            },
            dataIndex: "full_name",
            key: "full_name",
            ellipsis: true,
            width: "12%",
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            render: (text, record) => {
              return isEditing ? (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    defaultValue={text}
                    onChange={(e) => setEditedName(e.target.value)}
                    style={{ width: "100%", paddingRight: 20 }}
                    onFocus={() => {
                      setFocusedRow(record.id)

                      if (focusedRow === blurRow) {
                        if (editedName) {
                          updateServiceName(blurRow, editedName)
                          setEditedName("")
                        }
                      }
                    }}
                    onBlur={() => setBlurRow(record.id)}
                  />

                  {focusedRow === record.id && (
                    <Icon
                      type={loading ? "loading" : "save"}
                      className="save-icon"
                      style={{
                        fontSize: 18,
                        position: "absolute",
                        right: "10px",
                        cursor: "pointer",
                        color: "#0078ff",
                      }}
                      onClick={() => handleUpdateServiceName(record.id)}
                    />
                  )}
                </div>
              ) : (
                <div>{text}</div>
              )
            },
          },

          selectedSupplier === "TUTTI" && {
            title: "Provider",
            dataIndex: "provider",
            key: "provider",
            ellipsis: true,
            width: "8%",
            render: (text, record) => (
              <div style={{ padding: 0 }}>
                <img
                  src={`${baseUrlImg}/icon/${record.provider_name}-icon.svg`}
                  style={{
                    width: "28px",
                    height: "28px",
                    padding: 0,
                    marginRight: "5px",
                  }}
                />
                {record.provider_name}
              </div>
            ),
          },

          visibleColumns.Category && {
            title: "Category",
            dataIndex: "category",
            key: "category",
            ellipsis: true,
            width: "15%",
            render: (text, record) => (
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                defaultValue={record.category}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                onChange={(value) => {
                  changeServiceCategory(record.id, value)
                  getAllServices(
                    activeSkinId,
                    selectedSupplier !== "TUTTI" ? selectedSupplier : null
                  )
                }}
                treeData={[
                  {
                    title: "Uncategorized",
                    value: 47,
                    key: "uncategorized",
                  },
                  ...transformToTreeData(servicesTree),
                ]}
                filterTreeNode={(inputValue, treeNode) => {
                  return treeNode.props.title
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }}
              />
              // <Select
              //   defaultValue={record.category}
              //   onChange={(value) => {
              //     changeServiceCategory(record.id, value)
              //   }}
              //   style={{ width: "100%" }}
              // >
              //   {allServices?.categories?.map((c) => (
              //     <Option
              //       value={c.id}
              //       key={c.id}
              //       style={{
              //         textTransform: "none",
              //       }}
              //     >
              //       {c.name}
              //     </Option>
              //   ))}
              // </Select>
            ),
          },

          visibleColumns.Icon && {
            title: "Icona",
            dataIndex: "icon",
            key: "icon",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  onClick={() => handleSettingsClick(record)}
                  src={
                    record.icon
                      ? `${baseUrlImg}/${record.icon}`
                      : images["placeholder"]
                  }
                  alt="icon"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                />

                <a
                  href={`${baseUrlImg}/${record.icon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Logo && {
            title: "Logo",
            dataIndex: "logo",
            key: "logo",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  onClick={() => handleSettingsClick(record)}
                  src={
                    // selectedSupplier === "E-Pay"
                    //   ?
                    record.logo
                      ? `${baseUrlImg}/${record.logo}`
                      : images["placeholder"]
                    // : images[record.name]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                />
                <a
                  href={`${baseUrlImg}/${record.logo}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Scontrino && {
            title: "Logo scontrino",
            dataIndex: "scontrino",
            key: "scontrino",
            ellipsis: true,
            width: screenWidth < 1490 ? "8%" : "12%",
            render: (text, record) => (
              <>
                <img
                  onClick={() => handleSettingsClick(record)}
                  src={
                    record.scontrino
                      ? `${baseUrlImg}/${record.scontrino}`
                      : images["placeholder"]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                />
                <a
                  href={`${baseUrlImg}/${record.scontrino}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },
          visibleColumns.Background && {
            title: "Background",
            dataIndex: "background",
            key: "background",
            width: screenWidth < 1490 ? "8%" : "12%",
            ellipsis: true,
            render: (text, record) => (
              <>
                <img
                  onClick={() => handleSettingsClick(record)}
                  src={
                    record.background
                      ? `${baseUrlImg}/${record.background}`
                      : images["placeholder"]
                  }
                  alt="logo"
                  width={30}
                  height={30}
                  style={{ objectFit: "contain", marginRight: 10 }}
                />
                <a
                  href={`${baseUrlImg}/${record.background}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image-link"
                >
                  {text?.split("/").pop()}
                </a>
              </>
            ),
          },

          // visibleColumns.Provvigione && {
          //   title: "Provvigione",
          //   dataIndex: "provvigione",
          //   key: "provvigione",
          //   ellipsis: true,
          //   align: "left",
          //   width: "14%",
          //   render: (text) => `${text.join(", ")}`,
          // },
          visibleColumns.Agenzia && {
            title: "Agenzia",
            dataIndex: "provvigione",
            key: "agenzia",
            ellipsis: true,
            align: "center",
            width: "8%",
            render: (text) => {
              if (!text || (Array.isArray(text) && text.length === 0)) {
                return "-"
              }

              const values = Array.isArray(text) ? text : text.split(",")

              const trimmedValues = values.map((val) => val?.trim()).filter(Boolean)

              const allSame = trimmedValues.every((val) => val === trimmedValues[0])

              if (allSame) {
                return `${trimmedValues[0]}%`
              } else if (trimmedValues.length === 0) {
                return "-"
              } else {
                return trimmedValues.map((val) => `${val}%`).join(", ")
              }
            },
          },

          visibleColumns["Aggio Skin"] && {
            title: "Aggio Skin",
            dataIndex: "skin_percentage",
            key: "skin",
            ellipsis: true,
            align: "center",
            width: "8%",
            render: (text) => {
              return `${text}%`
            },
          },

          visibleColumns["Aggio Bruto"] && {
            title: "Aggio Bruto",
            dataIndex: "aggio_net",
            key: "aggio_net",
            ellipsis: true,
            align: "center",
            width: "8%",
            render: (text) => {
              if (!text || (Array.isArray(text) && text.length === 0)) {
                return "-"
              }

              const values = Array.isArray(text) ? text : text.split(",")

              const trimmedValues = values.map((val) => val?.trim()).filter(Boolean)

              const allSame = trimmedValues.every((val) => val === trimmedValues[0])

              if (allSame) {
                return `${trimmedValues[0]}%`
              } else if (trimmedValues.length === 0) {
                return "-"
              } else {
                return trimmedValues.map((val) => `${val}%`).join(", ")
              }
            },
          },
        
          visibleColumns.Impostazioni && {
            title: "Impostazioni",
            dataIndex: "impostazioni",
            key: "impostazioni",
            width: "6%",
            ellipsis: true,
            filters: [
              { text: "Active", value: 1 },
              { text: "Inactive", value: 0 },
            ],
            onFilter: (value, record) => record.active === value,
            render: (text, record) => {
              return (
                <div className="icons">
                  <Switch
                    style={{ width: 30, marginRight: 5 }}
                    size="small"
                    checked={record.active === 1}
                    // checkedChildren="Attivo"
                    // unCheckedChildren="Inattivo"
                    onChange={async (checked) => {
                      if (checked) {
                        if (record.active === 0) {
                          await UpdateServicesChangeStatus(
                            record.name,
                            record.full_name,
                            record.id,
                            true,
                            activeSkinId,
                            async () => {
                              await getAllServices(
                                activeSkinId,
                                selectedSupplier !== "TUTTI"
                                  ? selectedSupplier
                                  : null
                              )
                            }
                          )
                        } else {
                          notification["success"]({
                            message: "Lo stato è già attivato",
                          })
                        }
                      } else {
                        if (record.active === 1) {
                          await UpdateServicesChangeStatus(
                            record.name,
                            record.full_name,
                            record.id,
                            false,
                            activeSkinId,
                            async () => {
                              await getAllServices(
                                activeSkinId,
                                selectedSupplier !== "TUTTI"
                                  ? selectedSupplier
                                  : null
                              )
                            }
                          )
                        } else {
                          notification["error"]({
                            message: "Lo stato è già disattivato",
                          })
                        }
                      }
                    }}
                  />

                  <i
                    className="fal fa-cog"
                    onClick={() => handleSettingsClick(record)}
                  />

                  {record.service_type === "exalogic" && (
                    <i
                      className="fal fa-user"
                      onClick={async () => {
                        await getSelectedCompany(record.id)
                        await getContiAccount(record.service_id)
                        setOpenModal(true)
                        setService(record)
                      }}
                    />
                  )}
                </div>
              )
            },
          },
        ].filter(Boolean)

  const data =
    allServices &&
    allServices?.companies
      ?.filter(
        (s) =>
          s?.full_name?.toLowerCase().includes(filterName.toLowerCase()) &&
          (activeCategory === "TUTTI" ||
            s.category_id == activeCategory ||
            allServices.categories.some(
              (category) =>
                category.id === s.category_id && category.parent_id == activeCategory
            ))
      )
      .map((s) => {
        return {
          id: s.number_id,
          full_name: s.full_name,
          name: s.name,
          category: s.category,
          icon: s.icon,
          logo: s.logo,
          scontrino: s.scontrino,
          background: s.background,
          provvigione: s.services_agency_guadagno,
          aggio_net: s.services_system_aggio,
          skin_percentage: s.skin_percentage,
          service_type: s.service_type,
          service_id: s.service_id,
          active: s.active,
          category_id: s.category_id,
          company_id: s.name,
          provider_name: s.provider_name,
        }
      })

  const id = props.match.params.id

  //VISURE EDIT

  const filterVisureServicesByCompanyId = (companyId) =>
    (allServices?.companies || []).filter(
      ({ number_id }) => number_id === Number(companyId)
    )

  if (!isNaN(id)) {
    return (
      <AdminSubServizzi
        services={filterVisureServicesByCompanyId(id)}
        serviceSelected={id}
      />
    )
  }

  return (
    <>
      <ContiGiocoModal
        open={openModal}
        onClose={() => {
          setSelectedCompany(null)
          setOpenModal(false)
          setContiAccount(null)
          setNewContiStatus(null)
        }}
        service={service}
      />
      <AddServiceModalForm
        isVisible={addServiceModalVisible}
        onClose={() => setAddServiceModalVisible(false)}
        accountInfo={accountInfo}
        providers={providers}
      />
      <div
        className="Container Annunci AllTransazioni AllServices"
        style={{ paddingBottom: screenWidth < 500 && "100px", width: "100%" }}
      >
        {MessaggiComponent}
        <div className="container-fluid overview">
          <div className="panels-container">
            <div className="sort-annunci adminAnnunci ">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 4,
                }}
              >
                <div className="filters">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1 className="headerTop">
                      <Icon type="shop" />
                      Servizi
                    </h1>
                    {screenWidth < 500 && (
                      <Input
                        className="search-service"
                        placeholder="Cerca Servizi "
                        type="text"
                        suffix={<Icon type="search" />}
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    )}
                  </div>

                  <div style={{ display: "flex" }}>
                    <Select
                      defaultValue={selectedSupplier}
                      onChange={(value) => {
                        setSelectedSupplier(value)
                        getAllServices(
                          activeSkinId,
                          value !== "TUTTI" ? value : null
                        )
                        history.push(`/back-office/servizi/${value}`)
                      }}
                    >
                      <Option value="TUTTI">Tutti Servizi</Option>

                      {providers &&
                        providers.map((provider) => (
                          <Option value={provider.name} key={provider.name}>
                            <img
                              width={30}
                              height={30}
                              src={`${baseUrlImg}/icon/${provider.name}-icon.svg`}
                            />{" "}
                            {provider.name}
                          </Option>
                        ))}
                    </Select>
                    <TreeSelect
                      showSearch
                      style={{ width: "100%" }}
                      value={activeCategory}
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      placeholder="Seleziona una categoria"
                      allowClear
                      onChange={(value) => {
                        setActiveCategory(value)
                      }}
                      treeData={[
                        { title: "TUTTI", value: "TUTTI", key: "TUTTI" },
                        ...transformToTreeData(servicesTree),
                      ]}
                      filterTreeNode={(inputValue, treeNode) => {
                        return treeNode.props.title
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }}
                    />
                    {/* <Select
                    defaultValue={activeCategory}
                    onChange={(value) => {
                      console.log(value)
                      setActiveCategory(value)
                    }}
                  >
                    <Option value="TUTTI">TUTTI</Option>
                    {allServices?.categories?.map((c) => (
                      <Option value={c.id} key={c.id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select> */}
                    <Button
                      icon="plus"
                      type="primary"
                      className="add-service-btn"
                      // onClick={() => history.push("/back-office/add-product")}
                      onClick={() => setAddServiceModalVisible(true)}
                    >
                      Aggiungi Servizio
                    </Button>
                    {screenWidth > 500 && (
                      <Input
                        className="search-service"
                        placeholder="Cerca Servizi "
                        type="text"
                        suffix={<Icon type="search" />}
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                {screenWidth > 600 && (
                  <Popover
                    placement="leftBottom"
                    content={
                      <div
                        style={{ display: "flex", flexDirection: "column", gap: 5 }}
                      >
                        {Object.keys(visibleColumns).map((colKey) => (
                          <Checkbox
                            key={colKey}
                            checked={visibleColumns[colKey]}
                            onChange={(e) => handleCheckboxChange(e, colKey)}
                            style={{ marginLeft: 8 }}
                          >
                            {colKey}
                          </Checkbox>
                        ))}
                      </div>
                    }
                    trigger="click"
                  >
                    <Button style={{ height: 37, marginRight: 5 }}>Display</Button>
                  </Popover>
                )}
              </div>

              {/* {screenWidth > 600 && (
                <div className="hide-columns">
                  {Object.keys(visibleColumns).map((colKey) => (
                    <Checkbox
                      key={colKey}
                      checked={visibleColumns[colKey]}
                      onChange={(e) => handleCheckboxChange(e, colKey)}
                    >
                      {colKey}
                    </Checkbox>
                  ))}
                </div>
              )} */}
            </div>
            {servicesLoader && <div className="loaderAdmin"></div>}

            {!servicesLoader && (
              <Table
                // className="table-striped-rows"
                style={{ width: "100%" }}
                columns={columns}
                pagination={false}
                showHeader={true}
                dataSource={data}
                rowKey={(record) => record.id}
                scroll={{ y: "calc(100vh - 205px)" }} //sticky header
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  activeSkinId: state.main.activeSkinId,
  allServices: state.auth.allServices,
  servicesLoader: state.auth.servicesLoader,
  selectedCompany: state.main.selectedCompany,
  skinList: state.auth.skinList,
  contiGiocoServices: state.auth.contiGiocoServices,
  providers: state.main.providers,
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
})

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewServicesAdmin)
)
