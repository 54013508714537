import React, { useEffect, useState, useRef } from "react"
import { skin } from "config/api"
import "./winterVideo.css"
import { usePrevious } from "utils/HelperFunc"

function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const allowedDays = [1, 2]
const allowedMonths = [0]
const month = new Date().getMonth()
const day = new Date().getDate()
const allowed =
  allowedMonths.includes(month) && allowedDays.includes(parseInt(day.toString()))

const WinterVideo = () => {
  const shouldShowVideo = () => {
    const videoOpenDate = localStorage.getItem("winterVideoDate")
    return (
      !videoOpenDate ||
      (IsJsonString(videoOpenDate) &&
        new Date().getTime() >=
          new Date(new Date(JSON.parse(videoOpenDate))).getTime() + 86400000)
    )
  }

  const [visibility, setVisibility] = useState(shouldShowVideo() && allowed)

  const close = () => setVisibility(false)

  const prevVisibility = usePrevious(visibility, useRef, useEffect)
  useEffect(() => {
    if (!visibility) {
      document.body.style.overflow = "auto"
    }
    if (prevVisibility && !visibility) {
      localStorage.setItem("winterVideoDate", JSON.stringify(new Date()))
    }
  }, [visibility, prevVisibility])

  return (
    visibility && (
      <div className="winter-video">
        <div className="x">
          <span>Un grande 2025!</span> <i onClick={close} className="fal fa-times" />
        </div>
        {/* <iframe
          id="winter-video-frame"
          frameborder="0"
          allowfullscreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="YouTube video player"
          width="100%"
          height="100%"
         // src="https://www.youtube.com/embed/M-H4TtxnssQ?autoplay=1&amp;mute=1&amp;controls=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;cc_load_policy=1&amp;"
          src="https://www.youtube-nocookie.com/embed/E72M9cA9o7c?autoplay=1&amp;mute=1&amp;controls=1&amp;origin=http%3A%2F%2Flocalhost%3A3000&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;cc_load_policy=1&amp;"
        /> */}
      </div>
    )
  )
}

export default WinterVideo
