import React, { useEffect } from "react"
import images from "themes/images"
import { baseUrlImg } from "../../../config/api"
import { MainActions, AuthActions } from "redux-store/models"
import { connect } from "react-redux"

const AdminWallet = ({
  accountInfo,
  getProviders,
  providers,
  getContiGiocoServices,
  contiGiocoServices,
}) => {
  useEffect(() => {
    getProviders()
    getContiGiocoServices()
  }, [getProviders])

  if (accountInfo?.profile?.role?.name === "main_admin") {
    return (
      <div className="admin-wallets">
        <div className="admin-wallets-carousel">
          {providers
            .filter((provider) => provider.name !== "Exalogic")
            .map((provider, i) => {
              return (
                <div key={i}>
                  <img
                    src={`${baseUrlImg}/logo/${provider?.name?.toLowerCase()}.svg`}
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = `${baseUrlImg}/icon/${provider?.name}-icon.svg`
                        ? `${baseUrlImg}/icon/${provider?.name}-icon.svg`
                        : `${baseUrlImg}/logo/default.jpeg`
                    }}
                    alt={`${provider?.name} logo`}
                  />

                  <span>&euro; {parseFloat(provider.wallet).toFixed(2)}</span>
                </div>
              )
            })}
          {Array.isArray(contiGiocoServices) &&
            contiGiocoServices.map((provider, i) => (
              <div key={i}>
                <img
                  src={`${baseUrlImg}/icon/${provider?.company?.name}-icon.svg`}
                  alt={`${provider?.company?.name}`}
                />
                <span>&euro; {parseFloat(provider.wallet).toFixed(2)}</span>
              </div>
            ))}
        </div>
      </div>
    )
  }
  return null
}

const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  providers: state.main.providers,
  contiGiocoServices: state.auth.contiGiocoServices,
})

export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  AdminWallet
)
