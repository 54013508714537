import React, { useEffect, useState } from "react"
import { azioni } from "config"
import { capitalize, get, includes } from "lodash"
import { Tooltip } from "antd"
import VisureFilter from "routes/domains/FormVisure/VisureFilter"
import { connect } from "react-redux"
import { useCustomerly } from "react-live-chat-customerly"
import { serviceIcons } from "../../routesMobile/domains/Dashboard"
const TabBody = ({
  tab,
  setActiveTab,
  accountInfo,
  logOut,
  services,
  servicesTree,
  setMenu,
  togglePrenotazione,
  visureMobileDetails,
}) => {
  const { load, open } = useCustomerly()

  const [visureVis, setVisureVis] = useState(visureMobileDetails?.activeCategory)
  const openChat = () => {
    open()
  }

  useEffect(() => {
    load()
  })

  return tab === 1 ? (
    <div className="tabBody body1">
      {accountInfo?.profile ? (
        <div className="tabBody--item">
          <i className="fal fa-user-circle" aria-hidden="true"></i>

          <Tooltip title={accountInfo?.profile?.name}>
            <div className="nameUser">{accountInfo?.profile?.name}</div>
          </Tooltip>

          <span>{accountInfo?.profile?.wallet}€</span>
        </div>
      ) : (
        <>
          <div className="tabBody--item">
            <i className="fal fa-store"></i>
            Servizi
          </div>
        </>
      )}
      {/* {accountInfo?.profile?.role && <div className="tabBody--item" onClick={togglePrenotazione}>
        <img src={images["BOLLETINO-logo"]} alt="bolletino logo"/>
        PRENOTAZIONE BOLLETINI
      </div>} */}

      {accountInfo?.profile?.role?.name === "super_admin" && (
        <div
          className="tabBody--item"
          onClick={() => {
            window.location.hash = "registerAgent"
          }}
        >
          <i className="fal fa-user"></i>
          Register Agent
        </div>
      )}
      {(accountInfo?.profile?.role?.name === "agent" ||
        accountInfo?.profile?.role?.name === "super_admin") && (
        <div
          className="tabBody--item"
          onClick={() => {
            window.location.hash = "registerAgency"
          }}
        >
          <i className="fal fa-user"></i>
          Registra Agenzia
        </div>
      )}
      {accountInfo?.profile?.role?.name === "agency" && (
        <div
          className="tabBody--item"
          onClick={() => {
            window.location.hash = "ru"
          }}
        >
          <i className="fal fa-user"></i>
          Registra Nuovi Utenti
        </div>
      )}
      {accountInfo?.profile && (
        <div
          className="tabBody--item"
          onClick={() => {
            window.location.hash = "/scan-mobile"
          }}
        >
          <i className="fal fa-qrcode"></i>
          Scan qr Code
        </div>
      )}

      {/* <div
          className="tabBody--item"
          onClick={() => {
            window.location.hash = "/prenotaBolletino";
          }}
        >
          <i className="fal fa-store"></i>
         Prenotazioni bolletini
        </div> */}
      {/* <div
        className={"tabBody--item" + (window.location.hash.includes("products") ? " active" : "")}
        onClick={() => {
          window.location.hash = "products";
        }}
      >
        <i className="fal fa-shopping-cart"></i>
        Shop
      </div> */}
      <div
        className={
          "tabBody--item" +
          (window.location.hash.includes("orders-shop") ? " active" : "")
        }
        onClick={() => {
          window.location.hash = "orders-shop"
        }}
      >
        <i className="fal fa-shopping-cart"></i>
        Ordine Shop
      </div>

      {azioni.map((tab) => {
        return (
          includes(tab.displayRole, get(accountInfo, "profile.role.name")) && (
            <div
              key={tab.id}
              onClick={() => {
                window.location.hash = `${tab.link}`
              }}
              className={
                "tabBody--item" +
                (window.location.hash.includes(tab.link) ? " active" : "")
              }
            >
              <i className={`icon ${tab.i}`}></i>
              {tab.name}
            </div>
          )
        )
      })}
      <div className="tabBody--a">
        <a
          href="#"
          className="tabBody--a__b"
          rel="noopener noreferrer"
          onClick={openChat}
        >
          <i className="fal fa-cog" aria-hidden="true"></i>
          Helpdesk
        </a>
        {accountInfo?.profile ? (
          <div onClick={() => logOut()} className="tabBody--a__b">
            LOGOUT <i className="fal fa-sign-out"></i>
          </div>
        ) : (
          <div
            onClick={() => {
              window.location.hash = "login"
            }}
            className="tabBody--a__b"
          >
            LOGIN <i className="fal fa-sign-out"></i>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="tabBody body2">
      {accountInfo?.profile && (
        <div className="tabBody--item">
          <i className="fal fa-user-circle" aria-hidden="true"></i>

          <Tooltip title={accountInfo?.profile?.name}>
            {" "}
            <div className="nameUser">{accountInfo?.profile?.name}</div>
          </Tooltip>

          <span>{accountInfo?.profile?.wallet}€</span>
        </div>
      )}
      {servicesTree
        ?.filter(
          (service, index, self) =>
            service.parent_id === null &&
            service.name !== "Uncategorized" &&
            index === self.findIndex((s) => s.name === service.name)
        )
        .map((service) => {
          const icon = serviceIcons[service.id] || "fa-question-circle"
          return (
            <div
              key={service.id}
              id={service.id}
              className="tabBody--item"
              onClick={() => {
                setMenu("out")
                setTimeout(() => {
                  setMenu(false)
                  setActiveTab(service.id)
                  //TODO
                  //const tabtutte = document.getElementById("tab0")
                  // const scrollToElement = (elem) => {
                  //   if (elem) {
                  //     setTimeout(() => {
                  //       elem.click()
                  //       elem.scrollIntoView({
                  //         behavior: "smooth",
                  //         block: "center",
                  //         inline: "nearest",
                  //       })
                  //     }, 100)
                  //   }
                  // }

                  // if (tabtutte) {
                  //   tabtutte.click()
                  //   setTimeout(() => {
                  //     const el = document.querySelector(
                  //       #${
                  //         category.id && category.id.includes(" ")
                  //           ? category.id.split(" ").join("")
                  //           : category.id
                  //       }
                  //     )
                  //     console.log(el)
                  //     scrollToElement(el)
                  //   }, 100)
                  // } else {
                  //   window.location.hash = "dashboard"
                  //   setTimeout(() => {
                  //     const el = document.querySelector(#${category.id})
                  //     scrollToElement(el)
                  //   }, 300)
                  // }
                }, 500)
              }}
            >
              <i className={`fal ${icon}`} aria-hidden="true"></i>{" "}
              <span>{service.name}</span>{" "}
              <i className="fal fa-chevron-right" aria-hidden="true"></i>{" "}
            </div>
          )
        })}

      {/* {(Object.keys(services) || []).map((categoryKey) => {
        if (
          [
            "INDACO",
            "SPEDIZ",
            "INFOC",
            "CRYPTOSER",
            "ICSFR",
            "BILLEX",
            "UNC",
            "RTELC",
          ].includes(categoryKey)
        ) {
          return
        }
        return services[categoryKey]?.name === "Visure" ? (
          <React.Fragment>
            <div class="tabBody--item" onClick={() => setVisureVis(!visureVis)}>
              <i class="fal fa-file-alt" aria-hidden="true"></i>
              <span> Visure</span>
              <i
                className={`fal ${
                  visureVis ? "fa-chevron-down" : " fa-chevron-right"
                }`}
                aria-hidden="true"
                style={{ paddingLeft: "24px" }}
              ></i>{" "}
            </div>
            {visureVis && (
              <VisureFilter
                className="is-mobile"
                handleClick={() => setMenu(false)}
              />
            )}
          </React.Fragment>
        ) : !services[categoryKey]?.name.includes("TELEFONICHE ") ? (
          <div
            key={categoryKey}
            className="tabBody--item"
            onClick={() => {
              setMenu("out")
              setTimeout(() => {
                setMenu(false)
                const tabtutte = document.getElementById("tab0")
                function funx(elem) {
                  if (elem) {
                    setTimeout(() => {
                      elem.click()
                      elem.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      })
                    }, 100)
                  }
                }

                if (tabtutte) {
                  tabtutte.click()
                  setTimeout(() => {
                    const el = document.querySelector(
                      `#${
                        categoryKey && categoryKey.includes(" ")
                          ? categoryKey.split(" ").join("")
                          : categoryKey
                      }`
                    )
                    console.log(el)
                    funx(el)
                  }, 100)
                } else {
                  window.location.hash = "dashboard"
                  setTimeout(() => {
                    const el = document.querySelector(`#${categoryKey}`)
                    funx(el)
                  }, 300)
                }

                if (categoryKey === "BGLT" || categoryKey === "VSRE") {
                  window.location.hash = "forms"
                }
              }, 500)
            }}
          >
            <i
              className={`fal ${categoryKey === "SPRDPST" ? "PRDPST" : categoryKey}`}
              aria-hidden="true"
            ></i>{" "}
            <span>
              {" "}
              {categoryKey === "RTELD"
                ? "Ricariche Telefoniche"
                : capitalize(services[categoryKey]?.name)}
            </span>
            <i className="fal fa-chevron-right" aria-hidden="true"></i>{" "}
          </div>
        ) : (
          <div
            key={categoryKey}
            className="tabBody--item tel"
            onClick={() => {
              setMenu("out")
              setTimeout(() => {
                setMenu(false)
                window.location.hash = "dashboard"
                setTimeout(() => {
                  const el = document.getElementById(
                    "tabRTELC,RTELD,RTELI,CRYPTOSER"
                  )
                  if (el) {
                    el.click()
                    el.scrollIntoView({
                      behavior: "smooth",
                    })
                    const panel = document.getElementById("RTELD")
                    if (panel) {
                      panel.click()
                    }
                  }
                }, 300)
              }, 500)
            }}
          >
            <i className={`fal ${categoryKey}`} aria-hidden="true"></i>{" "}
            <span> Ricariche Telefono</span>
            <i className="fal fa-chevron-right" aria-hidden="true"></i>{" "}
          </div>
        )
      })} */}
      {/* <div
        className={
          "tabBody--item" +
          (window.location.hash.includes("products") ? " active" : "")
        }
        onClick={() => {
          window.location.hash = "products"
        }}
      >
        <i className="fal fa-shopping-cart"></i>
        Shop
        <i className="fal fa-chevron-right"></i>
      </div> */}
      {accountInfo?.profile && (
        <div
          className={
            "tabBody--item" +
            (window.location.hash.includes("spedizioni") ? " active" : "")
          }
          onClick={() => {
            window.location.hash = "spedizioni"
          }}
        >
          <i className="fal fa-dolly"></i>
          Spedizioni
          <i className="fal fa-chevron-right"></i>
        </div>
      )}

      <div className="tabBody--a">
        <a
          href="#"
          className="tabBody--a__b"
          rel="noopener noreferrer"
          onClick={openChat}
        >
          <i className="fal fa-cog" aria-hidden="true"></i>
          Helpdesk
        </a>
        {accountInfo?.profile ? (
          <div onClick={() => logOut()} className="tabBody--a__b">
            LOGOUT <i className="fal fa-sign-out"></i>
          </div>
        ) : (
          <div
            onClick={() => {
              window.location.hash = "login"
            }}
            className="tabBody--a__b"
          >
            LOGIN <i className="fal fa-sign-out"></i>
          </div>
        )}
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => {
  return {
    visureMobileDetails: state.auth.visureMobileDetails,
    servicesTree: state.main.servicesTree,
  }
}

export default connect(mapsStateToProps)(TabBody)
