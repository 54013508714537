import React from "react"
import moment from "moment"

const CreateGiftCardTicket = (arr) => {
  const receipt = JSON.parse(arr?.arr?.receipt)


  return (
    <div className="json_spedizionepro">
      <h5 className="d-flex justify-content-center font-weight-bold">MRPAY CARD</h5>
      <div style={{ paddingTop: 10 }}>
        <strong>Importo:</strong> <span>{receipt.balance || receipt.amount}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Codice Voucher:</strong> <span>{receipt.code}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Codice Fiscale:</strong> <span>{receipt.cf}</span>
      </div>
      <div style={{ paddingTop: 10 }}>
        <strong>Scadenza:</strong>{" "}
        <span>{moment(receipt.expires_at).format("DD/MM/YYYY HH:mm:ss")}</span>
      </div>
    </div>
  )
}

export default CreateGiftCardTicket
