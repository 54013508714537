import React from "react"
import { connect } from "react-redux"
import { baseUrlImg } from "../../config/api"
import { withRouter } from "react-router"
import images from "themes/images"

const Footer = ({ skinExtras, screenWidth, accountInfo }) => {
  const skinName = localStorage.getItem("skin_name") || ""
  let accountData = {}
  let categories = []

  try {
    const accountDataString = localStorage.getItem("accountDataB")
    if (accountDataString) {
      accountData = JSON.parse(accountDataString)

      if (accountData.dashboard && Array.isArray(accountData.dashboard.dashboard)) {
        categories = accountData.dashboard.dashboard
      } else {
        console.warn(
          "Invalid accountData structure. Falling back to empty categories."
        )
      }
    }
  } catch (error) {
    console.error("Error parsing accountDataB from localStorage:", error)
  }

  const filteredCategories = Array.from(
    new Map(categories?.map((item) => [item.name, item])).values()
  )

  const handleCategoryClick = (catLink) => {
    window.history.pushState(null, "", `/#/dashboard/${catLink}`)
  }

  if (screenWidth <= 1024) return null

  return (
    <footer className={skinExtras.name === "Mrpay" ? "mrPayFooter" : ""}>
      <div className="top">
        <div className="maxWFooter maxWidth">
          {skinExtras.name === "Mrpay" && (
            <>
              <img
                src={images["mrPayFooter"]}
                style={{ position: "absolute", width: "100%", marginBottom: 70 }}
                alt="Footer"
              />
              <div
                style={{
                  position: "absolute",
                  transform: "translate(18%, -97%)",
                  color: "var(--accent-bg)",
                  fontSize: 26,
                  lineHeight: "30px",
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className="mid">
        <div className="maxWFooter maxWidth">
          <div className="colf">
            <img
              src={`${baseUrlImg}/logo/${skinName}-footer.svg`}
              className={skinExtras.name}
              alt="Footer Logo"
            />
            <p>
              <b>Contatti</b> <br />
              {skinExtras.address}
            </p>
            <p>
              <b>E-mail:</b> <br />
              {skinExtras.mail}
            </p>
            <p>
              <b>Telefono:</b> <br />
              {skinExtras.cel}
            </p>
          </div>

          {accountInfo?.token && (
            <div className="colf">
              <b>Menu</b>
              <div className="categories two-columns">
                {filteredCategories?.map((cat) => (
                  <p
                    key={cat.id}
                    className="category-name"
                    onClick={() => handleCategoryClick(cat.link)}
                  >
                    {cat.name}
                  </p>
                ))}
              </div>
            </div>
          )}

          <div className="colf">
            <b>{skinExtras.name}</b>
            <div className="categories">
              <p className="category-name">
                <a href={skinExtras.link1}>Azienda</a>
              </p>
              <p className="category-name">
                <a href={skinExtras.link2}>Servizi</a>
              </p>
              <p className="category-name">
                <a href={skinExtras.link3}>Area riservata</a>
              </p>
              <p className="category-name">
                <a href={skinExtras.link4}>Contatti</a>
              </p>
            </div>
          </div>

          <div className="colf newsletter">
            Scarica la progressive app {skinExtras.name}
            <div id="PWA">
              <img src={`${baseUrlImg}/logo/pwa.svg`} height={45} alt="PWA" />
            </div>
            <div className="socials">
              <i className="fab fa-instagram" />
              <i className="fab fa-pinterest" />
              <i className="fab fa-youtube" />
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="maxWFooter maxWidth">
          <p>© All Rights Reserved Altechsolutions - {new Date().getFullYear()}</p>

          <img src={`${baseUrlImg}/icon/Apay-icon.svg`} alt="Apay Icon" />

          <p>{skinExtras.account_name} | P.IVA: IT 03697881203</p>
        </div>
      </div>
    </footer>
  )
}

const mapStateToProps = ({ auth, main }) => ({
  skinExtras: auth.skinExtras,
  screenWidth: main.screenWidth,
  accountInfo: auth.accountInfo,
})

export default connect(mapStateToProps)(withRouter(Footer))
