import React, { useCallback } from "react"
import { baseUrlImg } from "../../../config/api"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"

const CompanyCards = ({
  companies,
  screenWidth,
  getCompanyServices,
  toggleFavorite,
  togglePopUp,
  setServiceS,
  getCategoryServices,
  categoryActive,
  subCategories,
  activeSubCategory,
  setShowIFrame,
  setCompanyServices,
}) => {
  const favouriteClick = async (e, companyName, favourite) => {
    e.stopPropagation()
    e.preventDefault()

    await (favourite
      ? toggleFavorite(companyName, "remove")
      : toggleFavorite(companyName, "set"))

    setTimeout(() => {
      if (subCategories && subCategories.length > 0) {
        getCategoryServices(activeSubCategory)
      } else {
        getCategoryServices(categoryActive)
      }
    }, 300)
  }

  const onMouseEnter = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.add("hover")
  }, [])

  const onMouseLeave = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.remove("hover")
  }, [])


  return (
    companies &&
    Array.isArray(companies) &&
    companies.map((company, index) => (
      <div
        className="mobileServices--body__item"
        key={index}
        onClick={() => {
          if (company.id === 177) {
            setShowIFrame(true)
          } else {
            //Pagamenti
            if (company.category_id === 46) {
              setServiceS(company)
            } else {
              if (!company.services) {
                if (company.services_as_company) {
                  setServiceS(company)
                } else {
                  getCompanyServices(company.id)
                }
              } else {
                setCompanyServices(company)
              }
            }
          }

          togglePopUp(true)
        }}
      >
        <img
          src={
            !company.services
              ? company.category_id !== 46
                ? company.icon
                  ? `${baseUrlImg}/${company.icon}`
                  : `${baseUrlImg}/${
                      company?.gallery
                        ? company?.gallery?.icon
                        : "uncategorized/placeholder.jpg"
                    }`
                : `${baseUrlImg}/icon/${company.id}.svg`
              : `${baseUrlImg}/${company?.services[0].icon}`
          }
          alt={company.name}
        />
        <span>
          {company.full_name
            ? company.full_name === "RAV"
              ? "MAV/RAV"
              : company.full_name
            : company.name}
        </span>
        {screenWidth > 1024 && (
          <i
            onMouseEnter={() => onMouseEnter(company.id)}
            onMouseLeave={() => onMouseLeave(company.id)}
            onClick={(e) =>
              favouriteClick(e, company.name, company.favourite, company.id)
            }
            data-id={`${company.id}comp`}
            className={`fal fa-star ${company.favourite ? "favourite" : ""}`}
          />
        )}
      </div>
    ))
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  screenWidth: state.main.screenWidth,
  skinExtras: state.auth.skinExtras,
  subCategories: state.main.subCategories,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  CompanyCards
)
