import React, { useState, useEffect } from "react";
import { MainActions, AuthActions } from "redux-store/models";
import { withRouter } from "react-router";
import { Collapse } from "antd";
import { connect } from "react-redux";
import CompanyCards from "../../../routes/domains/Dashboard/CompanyCards";

const { Panel } = Collapse;

const Favourites = (props) => {
  const { favorites } = props;
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    const firstKey = Object.keys(favorites)[0];
    if (firstKey) {
      setExpandedKeys([firstKey]);
    }
  }, [favorites]);

  const onPanelChange = (key) => {
    setExpandedKeys([key]); 
  };

  return (
    <Collapse
      accordion
      onChange={onPanelChange}
      activeKey={expandedKeys[0]} 
    >
      {Object.keys(favorites).map((categoryKey) => {
        const category = favorites[categoryKey];
        const subcategories = Object.keys(category).filter(
          (key) => key !== "name" && key !== "group"
        );

        return (
          <Panel header={categoryKey} key={categoryKey}>
            {subcategories.length > 0 ? (
              <div className="mobileServices--body">
                <CompanyCards
                  companies={subcategories.map(
                    (subcategoryKey) => category[subcategoryKey]
                  )}
                />
              </div>
            ) : (
              <p>No subcategories available</p>
            )}
          </Panel>
        );
      })}
    </Collapse>
  );
};

const mapStateToProps = (state) => ({
  favorites: state.main.favorites,
});

export default connect(mapStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(Favourites)
);
