import { Button, Modal, Form, Input } from "antd"
import React from "react"

const AddVariableService = ({
  isVisible = true,
  onClose,
  form,
  id,
  getSelectedCompany,
  createOneService,
}) => {
  const { getFieldDecorator, validateFields } = form

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (!err) {
        createOneService(values.cost, values.type, id, () => {
          getSelectedCompany(id)
          onClose()
          form.resetFields()
        })
      }
    })
  }

  return (
    <Modal
      className="add-service-modal"
      title="Add service"
      visible={isVisible}
      onCancel={onClose}
      width={500}
      footer={
        <Button type="primary" block onClick={handleSubmit}>
          Create
        </Button>
      }
    >
      <Form layout="vertical" className="add-service-form">
        {/* <Form.Item label="Service name">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service name!",
              },
            ],
          })(<Input placeholder="Service name" />)}
        </Form.Item> */}

        <Form.Item label="Service cost">
          {getFieldDecorator("cost", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service cost!",
              },
            ],
          })(<Input placeholder="Service cost" />)}
        </Form.Item>

        <Form.Item label="Ticket type">
          {getFieldDecorator("type", {
            rules: [
              {
                required: true,
                message: "Per favore seleziona un service type!",
              },
            ],
          })(<Input placeholder="Service type" />)}
        </Form.Item>
      </Form>
    </Modal>
  )
}

const AddVariableServiceForm = Form.create()(AddVariableService)
export default AddVariableServiceForm
