import React, { Fragment, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

const ServiceSwiper = ({
  allServices,
  serviceMobile,
  canChangeCost,
  hasCostZero,
  showNumPadCost,
  setShowNumPadCost,
  setCustomCost,
  changeService,
}) => {
  const servicesLength = Number(allServices?.length)

  const range = (start, end) => {
    const array = []
    for (let i = start; i <= end; i++) {
      array.push(i)
    }
    return array
  }

  console.log(serviceMobile)

  useEffect(() => {
    if (
      (serviceMobile.service_id !== "ACM000" &&
        parseFloat(serviceMobile.cost) === 0) ||
      serviceMobile.type === "V"
    ) {
      setShowNumPadCost(true)
    }
  }, [serviceMobile])

  return (
    <div
      className={
        "TotalServices " + (hasCostZero && servicesLength < 5 ? "defaultCost" : "")
      }
    >
      <React.Fragment>
        <Swiper slidesPerView={Math.min(servicesLength, 5)}>
          {allServices
            .sort((i, c) => Number(i?.cost) - Number(c?.cost))
            .map((item, index) => {
              const sameCostMobile =
                parseFloat(item?.cost) === parseFloat(serviceMobile?.cost)
              const sameIdMobile =
                item.service_id.toString() === serviceMobile?.service_id.toString()
              return (
                <SwiperSlide key={`${item.service_id}${index}`}>
                  <Fragment>
                    {
                      <div
                        className={`${parseFloat(item?.cost)} ${parseFloat(
                          serviceMobile?.cost
                        )} serv ${
                          (canChangeCost ? sameCostMobile : sameIdMobile)
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            (item.service_id !== "ACM000" &&
                              parseFloat(item.cost) === 0) ||
                            item.type === "V"
                          ) {
                            changeService(item)
                            setShowNumPadCost(true)
                          } else {
                            setShowNumPadCost(false)
                            changeService(item)
                            setCustomCost("")
                          }
                        }}
                      >
                        <div className="Bottom">
                          {parseFloat(item.cost) === 0 ? (
                            <span
                              className="Price"
                              style={{
                                fontSize: "14px",
                                textAlign: "center",
                                lineHeight: "16px",
                              }}
                            >
                              {item.service_id === "ACM000"
                                ? "Attivazione MasterCard"
                                : "Importo Variabile"}
                            </span>
                          ) : (
                            <>
                              <span className="Price">
                                {parseFloat(item.cost).toFixed(2)}
                              </span>
                              <span className="Euro">€</span>
                            </>
                          )}
                        </div>
                        <div
                          className="Upper"
                          style={{ background: serviceMobile.colorBg }}
                        >
                          <div className="Upper--Left"></div>
                          <div className="Upper--Right"></div>
                        </div>
                      </div>
                    }
                  </Fragment>
                </SwiperSlide>
              )
            })}
        </Swiper>

        {serviceMobile?.id === "AZLBTC000" || serviceMobile?.id === "AZBTC000" ? (
          showNumPadCost ? (
            <div
              className="serv"
              onClick={() => {
                setShowNumPadCost(false)
                setCustomCost("")
              }}
            >
              <span>x</span>
            </div>
          ) : (
            <div
              className="serv"
              onClick={() => {
                setShowNumPadCost(true)
              }}
            >
              <span>+</span>
            </div>
          )
        ) : null}
      </React.Fragment>
    </div>
  )
}

export default ServiceSwiper
