import React, { useCallback } from "react"
import { baseUrlImg } from "../../../config/api"
import { MainActions, AuthActions } from "redux-store/models"
import { connect } from "react-redux"

const RedeemCard = ({ screenWidth, togglePopUp, setServiceS }) => {
  const onMouseEnter = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.add("hover")
  }, [])

  const onMouseLeave = useCallback((companyId) => {
    const el = document.querySelector(`[data-id="${companyId}comp"]`)
    if (el) el.classList.remove("hover")
  }, [])
  return (
    <div
      className="mobileServices--body__item"
      key={"MrPayCard"}
      onClick={() => {
        togglePopUp(true)
        setServiceS({
          name: "Redeem",
        })
      }}
    >
      <img src={`${baseUrlImg}/icon/MrPayCard-icon.svg`} alt="MrPay Card" />
      <span>Redeem MrPay Card</span>
      {screenWidth > 1024 && (
        <i
          onMouseEnter={() => onMouseEnter("MrPayCard")}
          onMouseLeave={() => onMouseLeave("MrPayCard")}
          // onClick={(e) =>
          //   favouriteClick(e, company.name, company.favourite, company.id)
          // }
          data-id={`MrPayCardcomp`}
          className={`fal fa-star ${false ? "favourite" : ""}`}
        />
      )}
    </div>
  )
}

const mapsStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  RedeemCard
)
