import React, { useEffect, useState } from "react"
import { Form, Input, Button, Tooltip, Icon } from "antd"
import images from "themes/images"
import { MainActions, AuthActions } from "redux-store/models"
import { connect } from "react-redux"
import { get } from "lodash"

const GiftCardRedeemForm = (props) => {
  const {
    form,
    getConfigura,
    accountInfo,
    usersConfigura,
    redeemGiftCard,
    rechargeMobile,
    setRechargeMobile,
    togglePopUp,
    setServiceS,
  } = props
  const { getFieldDecorator, validateFields } = form
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (get(accountInfo, "profile.id") && Object.keys(usersConfigura).length < 1) {
      getConfigura(accountInfo.profile.id)
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFields((err, values) => {
      console.log(err)
      if (!err) {
        redeemGiftCard(
          values.giftCardCode,
          values.codiceFiscale,
          setLoading,
          form.resetFields
        )
      }
    })
  }

  return (
    <div
      className="modulePopUP modulePopUP1 telRechanrge"
      onClick={() => {
        togglePopUp(false)
        setServiceS([])
      }}
    >
      <div className="flip-container">
        <div className={`flipper`}>
          <div className="front">
            <div
              className="gift-card-redeem-container"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <div className="Logo">
                <div className="Logo--Help">
                  <img
                    src={"https://mrbo.mrwallet.app/gallery/icon/MrPayCard-icon.svg"}
                    alt="MrPay-LOGO"
                  />
                </div>
              </div>

              <div className="Close">
                <span
                  className="CloseButton"
                  onClick={() => {
                    togglePopUp(false)
                    setServiceS([])
                  }}
                >
                  <img src={images.close} alt="" />
                </span>
              </div>
              {rechargeMobile?.error || rechargeMobile?.message ? (
                <div className="messages">
                  <div className="closeM" onClick={() => setRechargeMobile({})}>
                    chiudi messaggi
                  </div>
                  {rechargeMobile?.error ? (
                    typeof rechargeMobile?.error === "object" ? (
                      Object.keys(rechargeMobile?.error).map((item, index) => (
                        <div className="errorM" key={index}>
                          <i className="fad fa-exclamation text-danger"></i>
                          {rechargeMobile?.error[item]}
                        </div>
                      ))
                    ) : (
                      <div className="errorM">
                        <i className="fad fa-exclamation text-danger"></i>
                        {rechargeMobile?.error}
                      </div>
                    )
                  ) : (
                    <div className="infoM">
                      <i className="fad fa-info text-info"></i>
                      {rechargeMobile?.message}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="Banner">
                    <div className="banner-img">
                      <img
                        src="https://mrbo.mrwallet.app/gallery/background/mrpaycard.jpeg"
                        alt="MrPay-LOGO"
                      />
                    </div>
                  </div>
                  <Form
                    onSubmit={handleSubmit}
                    className="redeem-form"
                    layout="vertical"
                  >
                    <Form.Item
                      label={
                        <span>
                          Codice Fiscale
                          <span className="required-asterisk">*</span>
                        </span>
                      }
                    >
                      {getFieldDecorator("codiceFiscale", {
                        initialValue: get(usersConfigura, "personal_number", ""),
                        //   rules: [
                        //     { required: true, message: "Inserisci il Codice Fiscale!" },
                        //     {
                        //       pattern:
                        //         /^[A-Z]{6}\d{2}[A-EHLMPRST]{1}\d{2}[A-Z]{1}\d{3}[A-Za-z]{1}$/,
                        //       message: "Il formato del Codice Fiscale non è corretto!",
                        //     },
                        //   ],
                      })(
                        <Input
                          placeholder="Inserisci il Codice Fiscale"
                          maxLength={16}
                          // readOnly
                          suffix={
                            <Tooltip
                              title="Il Codice Fiscale dev’essere lo stesso usato durante 
l’acquisto di MrPay Card "
                            >
                              <Icon
                                type="info-circle"
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          }
                        />
                      )}
                    </Form.Item>

                    <Form.Item
                      label={
                        <span>
                          Codice per riscattare MrPay Card
                          <span className="required-asterisk">*</span>
                        </span>
                      }
                    >
                      {getFieldDecorator("giftCardCode", {
                        rules: [
                          {
                            required: true,
                            message: "Inserisci il codice della MrPay Card!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Inserisci il codice della MrPay Card"
                          suffix={
                            <Tooltip
                              title="Inserire il codice PIN ricevuto nella ricevuta generata 
quando hai eseguito l’acquisto di MrPay Card."
                            >
                              <Icon
                                type="info-circle"
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          }
                        />
                      )}
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="redeem-button"
                      loading={loading}
                    >
                      Ricarica Conto
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  rechargeMobile: state.auth.rechargeMobile,
  usersConfigura: state.auth.usersConfigura,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  Form.create()(GiftCardRedeemForm)
)
