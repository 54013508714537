import React, { useEffect, useState } from "react"
import images from "../../../themes/images"
import ImageUploadComponent from "../adminModals/ServiziModal/ImageUploadComponent"
import { Button, Card, Checkbox, Col, Form, Icon, Input, Row, Tabs, Tooltip } from "antd"
import { baseUrlImg } from "../../../config/api"
import { connect } from "react-redux"
import { MainActions, AuthActions } from "redux-store/models"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import "./EditServiceForm.css"
import { useHistory } from "react-router-dom"
import BonusModal from "../adminModals/ServiziModal/BonusModal"
import AddVariableServiceForm from "./AddVariableService"

const allowedTypes = ["exalogic", "Payment System", "betflag"]

const EditService = ({
  id,
  selectedCompany,
  updateCompany,
  getSelectedCompany,
  UpdateServicesChangeStatus,
  activeSkinId,
  accountInfo,
  singleServiceChangeStatus,
  deleteOneService,
  createOneService,
}) => {
  const [logo, setLogo] = useState(null)
  const [logoScontrino, setLogoScontrino] = useState(null)
  const [icon, setIcon] = useState(null)
  const [bgImage, setBgImage] = useState(null)
  const [selectedService, setSelectedService] = useState("default")
  const [selectedServiceId, setSelectedServiceId] = useState("default")
  const [formData, setFormData] = useState({
    default: {
      servizio: "",
      commissioni: "",
      provigione: "",
      iva: "",
    },
  })
  const [openBonusModal, setOpenBonusModal] = useState(false)
  const [addServiceModal, setAddServiceModal] = useState(false)
  const [serviceChanges, setServiceChanges] = useState({})
  const [defaultChanges, setDefaultChanges] = useState({})
  const [content, setContent] = useState("")
  const [scontrinoContent, setScontrinoContent] = useState("")
  const [colorBg, setColorBg] = useState("")
  const [colorTesto, setColorTesto] = useState("")
  const [firstService, setFirstService] = useState()
  const [isEuro, setIsEuro] = useState(false)
  const [servicesAsCompany, setServicesAsCompany] = useState()
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const showAddServiceIcon =
    selectedCompany &&
    selectedCompany?.services?.some(
      (service) =>
        allowedTypes.includes(service.type) || parseInt(service.cost, 10) === 0
    )

  console.log(showAddServiceIcon)

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ align: [] }],
        ["link", "image", "video"],
      ],
    },
  }

  useEffect(() => {
    if (!selectedCompany || Object.keys(selectedCompany).length === 0) {
      getSelectedCompany(id)
    }
  }, [id])

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.services &&
      selectedCompany.services.length > 0
    ) {
      const firstService = selectedCompany.services[0]
      setFirstService(firstService)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.services &&
      selectedCompany.services.length > 0
    ) {
      setServicesAsCompany(selectedCompany.company.services_as_company)
      const firstService = selectedCompany.services[0]
      setFirstService(firstService)

      const initialFormData = {}
      selectedCompany.services.forEach((service) => {
        initialFormData[service.service_id] = {
          servizio: service.cost || "",
          commissioni: service.commissione || "",
          provigione: service.provigione || "",
          iva: service.iva || "",
          system_aggio: service.system_aggio || "",
        }
      })

      initialFormData["default"] = {
        servizio: firstService.cost || "",
        commissioni: firstService.commissione || "",
        provigione: firstService.provigione || "",
        iva: firstService.iva || "",
        system_aggio: firstService.system_aggio || "",
      }

      setFormData(initialFormData)

      if (firstService) {
        setLogo(firstService.logo)
        setBgImage(firstService.background)
        setIcon(firstService.icon)
        setLogoScontrino(firstService.scontrino)
        setColorBg(firstService.colorBg)
        setColorTesto(firstService.colorTesto)
        setContent(firstService.content)
        setScontrinoContent(firstService.content_scontrino)
      }
    }
  }, [selectedCompany])

  const handleChangeService = (value) => {
    setSelectedService(value)
    value === "default"
      ? setSelectedServiceId("default")
      : setSelectedServiceId(selectedCompany?.services[value]?.service_id)
  }

  const handleSubmit = async () => {
    let servicesToSend
    if (Object.keys(defaultChanges).length > 0) {
      servicesToSend = defaultChanges
    } else {
      servicesToSend = serviceChanges
    }

    try {
      await updateCompany(
        id,
        logo?.id,
        bgImage?.id,
        icon?.id,
        logoScontrino?.id,
        colorBg,
        colorTesto,
        servicesToSend,
        content,
        scontrinoContent,
        !isEuro,
        servicesAsCompany,
        setLoading
      )
      setTimeout(() => {
        getSelectedCompany(id)
      }, 1000)
    } catch (error) {
      console.error(error)
    } finally {
      setDefaultChanges({})
      setServiceChanges({})
    }
  }

  const handleFieldChange = (serviceId, field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceId]: {
        ...prevFormData[serviceId],
        [field]: value,
      },
    }))
    if (serviceId === "default") {
      setDefaultChanges((prevDefaultChanges) => ({
        ...prevDefaultChanges,
        default: {
          ...prevDefaultChanges.default,
          [field]: value,
        },
      }))
    } else {
      setServiceChanges((prevChanges) => {
        const updatedChanges = { ...prevChanges }
        if (!updatedChanges[serviceId]) {
          updatedChanges[serviceId] = {}
        }
        updatedChanges[serviceId][field] = value
        return updatedChanges
      })
    }
  }
  const handleQuillChange = (value, isScontrino) => {
    isScontrino ? setScontrinoContent(value) : setContent(value)
  }

  const handleImageSelect = (selectedImage, imageType) => {
    const setters = {
      logo: setLogo,
      icon: setIcon,
      logoScontrino: setLogoScontrino,
      bgImage: setBgImage,
    }

    const setter = setters[imageType]
    if (setter) {
      setter(selectedImage)
    }
  }

  const handleStatusChange = (newStatus) => {
    UpdateServicesChangeStatus(
      selectedCompany?.company?.name,
      selectedCompany?.company?.full_name,
      id,
      newStatus,
      activeSkinId,
      async () => {
        getSelectedCompany(id)
      }
    )
  }

  const handleSingleServiceStatusChange = (serviceId, newStatus) => {
    singleServiceChangeStatus(serviceId, newStatus, async () => {
      getSelectedCompany(id)
    })
  }

  const shouldShowDeleteIcon = (singleService) => {
    const hasCostZero = selectedCompany?.services?.some(
      (service) => parseInt(service.cost) === 0
    )

    return hasCostZero || allowedTypes.includes(singleService.type)
  }

  const handleDelete = (service_id) => {
    deleteOneService(service_id, async () => {
      getSelectedCompany(id)
    })
  }

  return (
    <>
      <BonusModal
        open={openBonusModal}
        onClose={() => {
          setOpenBonusModal(false)
        }}
        selectedCompany={selectedCompany}
      />
      <AddVariableServiceForm
        isVisible={addServiceModal}
        onClose={() => setAddServiceModal(false)}
        id={id}
        getSelectedCompany={getSelectedCompany}
        accountInfo={accountInfo}
        createOneService={createOneService}
      />
      <div className="edit-service">
        <div className="service-header">
          <div className="service-name">
            <div className="service-logo">
              <div onClick={() => history.goBack()} className="go-back">
                <i className="fal fa-chevron-left mr-1" />
                <img
                  src={`${baseUrlImg}/${firstService?.icon}`}
                  // onError={(e) => {
                  //   e.target.onerror = null
                  //   e.target.src = `${baseUrlImg}/${
                  //     firstService.logo ? firstService.logo : "/logo/default.jpeg"
                  //   }`
                  // }}
                  alt="service"
                />
              </div>
              <span>{selectedCompany?.company?.full_name}</span>
            </div>
          </div>
          <div className="service-header">
            <div className="service-name">
              <img
                src={
                  images[
                    selectedCompany?.company?.active === 0
                      ? "inactiveBtn"
                      : "activeBtn"
                  ]
                }
                className="activate-btn"
                onClick={() =>
                  handleStatusChange(selectedCompany?.company?.active === 0)
                }
                alt="Activate Button"
              />
              <img
                src={images["bonusBtn"]}
                className={`activate-btn ${
                  selectedCompany?.company?.active === 0 ? "inactive" : ""
                }`}
                onClick={() => {
                  if (selectedCompany?.company?.active !== 0) {
                    setOpenBonusModal(true)
                  }
                }}
                alt="Bonus Button"
              />
            </div>
            <div style={{ display: "flex", height: 40, alignItems: "center" }}>
              <Checkbox
                checked={servicesAsCompany === 1}
                onChange={(e) => setServicesAsCompany(e.target.checked ? 1 : 0)}
              >
                Services as company
              </Checkbox>
            </div>
          </div>
        </div>

        <div className="service-media">
          <div className="section-title">Media e colori</div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Logo"
                imageType="logo"
                value={logo}
                onImageSelect={(e) => handleImageSelect(e, "logo")}
                firstService={firstService}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Icon"
                imageType="icon"
                value={icon}
                onImageSelect={(e) => handleImageSelect(e, "icon")}
                firstService={firstService}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Logo scontrino"
                value={logoScontrino}
                imageType="scontrino"
                onImageSelect={(e) => handleImageSelect(e, "logoScontrino")}
                firstService={firstService}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <ImageUploadComponent
                label="Background"
                value={bgImage}
                imageType="background"
                onImageSelect={(e) => handleImageSelect(e, "bgImage")}
                firstService={firstService}
              />
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="colorBg" label="Colore background">
                <Input
                  value={colorBg}
                  className="color-input"
                  onChange={(e) => setColorBg(e.target.value)}
                  prefix={
                    <input
                      type="color"
                      onChange={(e) => setColorBg(e.target.value)}
                      value={colorBg}
                      style={{ width: 30, height: 30 }}
                    />
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="colorTesto" label="Colore Testo">
                <Input
                  value={colorTesto}
                  className="color-input"
                  onChange={(e) => setColorTesto(e.target.value)}
                  prefix={
                    <input
                      type="color"
                      onChange={(e) => setColorTesto(e.target.value)}
                      value={colorTesto}
                      style={{ width: 30, height: 30 }}
                    />
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="service-variables">
          <div className="section-title">Servizi e variabili</div>
          <Form.Item name="selectedService">
            <div className="services-row">
              <Row
                gutter={16}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "100%",
                }}
              >
                {selectedCompany?.services
                  ?.sort((a, b) => a.cost - b.cost)
                  .map((service, index) => (
                    <Tooltip title={service.name}>
                       <Col
                      xs={8}
                      sm={8}
                      md={3}
                      xl={2}
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingRight: "0px",
                      }}
                      className="service-col"
                    >
                      {shouldShowDeleteIcon(service) && (
                        <Icon
                          type="close"
                          className="delete-icon"
                          onClick={() => handleDelete(service.service_id)}
                        />
                      )}

                      <Card
                        onClick={() => handleChangeService(index)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedService === index ? "var(--accent-bg)" : "#fff",
                          width: "100%",
                          height: "66px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "4px",
                          padding: "0 10px",
                        }}
                      >
                        {selectedCompany.company.services_as_company ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Checkbox
                              checked={service.active === 1}
                              onChange={(e) => {
                                handleSingleServiceStatusChange(
                                  service.id,
                                  e.target.checked ? 1 : 0
                                )
                              }}
                            ></Checkbox>
                            <div className="service-sign" style={{ marginLeft: 5 }}>
                              {service.name}
                            </div>
                          </div>
                        ) : parseInt(service.cost, 10) === 0 ? (
                          <div className="service-sign" style={{ fontSize: 14 }}>
                            Variabile
                          </div>
                        ) : (
                          <>
                            <div className="service-cost">{service.cost}</div>
                            <div className="service-sign">Euro</div>
                          </>
                        )}
                      </Card>
                    </Col>
                    </Tooltip>
                   
                  ))}

                <Col
                  xs={8}
                  sm={8}
                  md={3}
                  xl={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingRight: "0px",
                  }}
                >
                  <Card
                    onClick={() => handleChangeService("default")}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedService === "default" ? "var(--accent-bg)" : "#fff",
                      width: "100%",
                      height: "66px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="service-cost"
                      style={{ marginTop: 0, fontWeight: 500, fontSize: 18 }}
                    >
                      Tutti
                    </div>
                  </Card>
                </Col>
                {showAddServiceIcon && (
                  <Col
                    xs={8}
                    sm={8}
                    md={3}
                    xl={2}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingRight: "0px",
                    }}
                  >
                    <Card
                      onClick={() => setAddServiceModal(true)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        width: "100%",
                        height: "66px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="service-cost"
                        style={{ marginTop: 0, fontSize: 30, fontWeight: "lighter" }}
                      >
                        +
                      </div>
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          </Form.Item>

          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="service-commissione"
          >
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="commissioni" label="Commissioni">
                <Input
                  value={formData[selectedServiceId]?.commissioni}
                  onChange={(e) =>
                    handleFieldChange(
                      selectedServiceId,
                      "commissioni",
                      e.target.value
                    )
                  }
                  placeholder="Commissioni"
                  suffix={<i className="far fa-euro-sign"></i>}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="provvigioni" label="Provvigioni agenzia">
                <Input
                  value={formData[selectedServiceId]?.provigione}
                  onChange={(e) =>
                    handleFieldChange(
                      selectedServiceId,
                      "provigione",
                      e.target.value
                    )
                  }
                  placeholder={`Provvigioni agenzia (${isEuro ? "€" : "%"})`}
                  suffix={
                    <i
                      className={isEuro ? "far fa-euro-sign" : "far fa-percent"}
                      onClick={() => setIsEuro(!isEuro)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="bonus" label="Bonus agenzia">
                <Input
                  className="bonus-ag"
                  placeholder="Bonus agenzia"
                  suffix={
                    <i
                      className="far fa-cog"
                      onClick={() => setOpenBonusModal(true)}
                    />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="system_aggio" label="Aggio netto">
                <Input
                  placeholder="Aggio"
                  value={formData[selectedServiceId]?.system_aggio}
                  onChange={(e) =>
                    handleFieldChange(
                      selectedServiceId,
                      "system_aggio",
                      e.target.value
                    )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            className="service-commissione"
          >
            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item name="iva" label="IVA" className="iva">
                <Input
                  placeholder="IVA"
                  value={formData[selectedServiceId]?.iva}
                  onChange={(e) =>
                    handleFieldChange(selectedServiceId, "iva", e.target.value)
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={6} xl={4}>
              <Form.Item
                name="descrizione"
                label="Descrizione IVA"
                className="descrizione"
              >
                <Input placeholder="Descrizione IVA" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="service-information">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="section-title">Informazioni</div>
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Guida all’uso" key="1">
                  <ReactQuill
                    value={content}
                    onChange={(e) => handleQuillChange(e, false)}
                    modules={modules}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Informazioni scontrino" key="2">
                  <ReactQuill
                    value={scontrinoContent}
                    onChange={(e) => handleQuillChange(e, true)}
                    modules={modules}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>

        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 20,
          }}
          className="form-buttons"
        >
          <Col xs={12} sm={12} md={6} xl={4}>
            <Button block type="default" onClick={() => history.goBack()}>
              CANCELLA
            </Button>
          </Col>

          <Col xs={12} sm={12} md={6} xl={4}>
            <Button block type="primary" onClick={handleSubmit} loading={loading}>
              SALVA
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

const EditServiceForm = Form.create()(EditService)

const mapStateToProps = (state) => ({
  selectedCompany: state.main.selectedCompany,
  activeSkinId: state.main.activeSkinId,
  accountInfo: state.auth.accountInfo,
})

export default connect(mapStateToProps, { ...AuthActions, ...MainActions })(
  EditServiceForm
)
