import React from "react"
import AdminPanelDom from "../domains/adminPanel/adminPanelDom"
import { connect } from "react-redux"
import EditUser from "./EditUser"
class AdminPanelListaUtenti extends React.Component {
  render() {
    return (
      <AdminPanelDom
        component={({ MessaggiComponent }) => (
          <EditUser
            forAdmin={true}
            MessaggiComponent={MessaggiComponent}
            activeSkinId={this.props.activeSkinId}
          />
        )}
        {...this.props}
      />
    )
  }
}
const mapStatToProps = (state) => ({
  activeSkinId: state.main.activeSkinId,
})
export default connect(mapStatToProps, null)(AdminPanelListaUtenti)
