import React from "react"
import { baseUrlImg } from "../../../config/api"

const CompanyCard = ({ category, company, service, img }) => {
  return (
    <div className="company-card">
      <div className="image">
        <img src={`${baseUrlImg}/${img}`} />
      </div>
      <div className="information">
        <div className="category-name">{category}</div>
        <div className="company-name">{company}</div>
        <div className="service-name">{service}</div>
      </div>
    </div>
  )
}

export default CompanyCard
