import { get, isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useCustomerly } from "react-live-chat-customerly"
import { connect } from "react-redux"

export const openChat = (screenWidth, innerHeight = window.innerHeight) => {
  var winparams =
    "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
    `resizable,screenX=${
      screenWidth >= 1000
        ? (screenWidth - screenWidth / 4) / 2
        : screenWidth >= 600 && screenWidth <= 1000
        ? screenWidth / 2 / 2
        : 0
    },screenY=${(innerHeight - 600) / 2},width=${
      screenWidth >= 1000
        ? screenWidth / 4
        : screenWidth >= 600 && screenWidth <= 1000
        ? screenWidth / 2
        : screenWidth
    },height=600`
  var htmlPop =
    "<iframe width=100% height=100%" +
    ' src="https://tawk.to/chat/5d443c5f7d27204601c8f6b8/default' +
    '"></iframe>'

  var printWindow = window.open("", "PDF", winparams)
  printWindow.document.title = "SUPPORT"
  printWindow.document.write(htmlPop)
}
const Chat = ({ screenWidth, accountInfo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { load, open, close, update } = useCustomerly()

  useEffect(() => {
    if (isEmpty(accountInfo)) {
      close() 
      load({}) 
      // Clear related local storage and cookies
      localStorage.removeItem("customerly_messenger/chatflows")
      localStorage.removeItem("customerly_messenger/fakeConversations")
      localStorage.removeItem("customerly_messenger/i18next/en-translation")
      localStorage.removeItem("customerly_messenger/i18next/it-translation")
      localStorage.removeItem("customerly_messenger/stats")
      document.cookie =
        "customerly_jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    } else {
      // Initialize chat for logged-in user
      const userOptions = {
        user_id: get(accountInfo, "profile.id"),
        name: get(accountInfo, "profile.name"),
        email: get(accountInfo, "profile.email"),
      }
      load(userOptions) 
      update(userOptions) 
    }
  }, [accountInfo])

  useEffect(() => {
    if (!isEmpty(accountInfo)) {
      const userOptions = {
        user_id: get(accountInfo, "profile.id"),
        name: get(accountInfo, "profile.name"),
        email: get(accountInfo, "profile.email"),
      }
      update(userOptions) 
    } else update()
  }, [accountInfo])


  useEffect(() => {
    return () => {
      close()
    }
  }, [])

  const openChat = () => {
    open()
    setIsOpen(true)
  }

  const closeChat = () => {
    close()
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          !isOpen ? openChat() : closeChat()
        }}
      >
        <i
          className="far fa-headset"
          style={
            screenWidth >= 1024
              ? {
                  paddingRight: "10px",
                  fontSize: "16px",
                  fontWeight: 300,
                }
              : {
                  fontSize: "16px",
                  fontWeight: 300,
                }
          }
        ></i>

        <span
          className="support-title"
          style={{ fontSize: "14px", fontFamily: "Roboto", fontWeight: 400 }}
        >
          Support
        </span>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => ({
  screenWidth: state.main.screenWidth,
  accountInfo: state.auth.accountInfo,
})
export default connect(mapStateToProps, null)(Chat)
