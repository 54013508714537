import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { MainActions, AuthActions } from "redux-store/models"

const NewCategoryList = ({
  getServicesTree,
  servicesTree,
  menuClassName,
  activeCategory,
  match,
  onClickCategory,
  getCategoryServices,
  setSubCategories,
  setServices,
  setShowRedeem,
}) => {
  useEffect(() => {
    getServicesTree()
  }, [])

  const id = match.params.id

  const selectedCategory = servicesTree.find(
    (category) => category.slug && category.slug.split("/").pop() === id
  )

  useEffect(() => {
    if (
      selectedCategory &&
      selectedCategory.children_recursive.length > 0 &&
      !activeCategory
    ) {
      const firstCategory = selectedCategory.children_recursive[0]
      getCategoryServices(firstCategory.id)
      onClickCategory(firstCategory)
      setShowRedeem(false)
    }
  }, [
    selectedCategory,
    activeCategory,
    getCategoryServices,
    onClickCategory,
    setShowRedeem,
  ])

  return (
    <aside className={`Categories ${menuClassName}`}>
      <div className="First">SERVIZI</div>
      {selectedCategory &&
        selectedCategory?.children_recursive.map((sub) => {
          return (
            <div
              key={sub?.id}
              className={`${activeCategory === sub.id ? "active" : ""}`}
              onClick={() => {
                if (sub?.children_recursive.length === 0) {
                  getCategoryServices(sub.id)
                  setSubCategories(null)
                } else {
                  setServices(null)
                  setSubCategories(sub.children_recursive)
                }
                onClickCategory(sub)
                setShowRedeem(false)
              }}
              data-cat={`${sub.id}`}
            >
              {sub.name}
            </div>
          )
        })}

      {/* MrPay Card only */}
      {selectedCategory && selectedCategory.id === 61 && (
        <div
          key="redeem"
          className={`${activeCategory === "redeem" ? "active" : ""}`}
          onClick={() => {
            setShowRedeem(true)
            onClickCategory({ id: "redeem", name: "redeem" })
          }}
        >
          Redeem
        </div>
      )}
    </aside>
  )
}

const mapsStateToProps = (state) => ({
  accountInfo: state.auth.accountInfo,
  servicesTree: state.main.servicesTree,
})

export default connect(mapsStateToProps, { ...MainActions, ...AuthActions })(
  withRouter(NewCategoryList)
)
