import React from "react"
import images from "themes/images"
import { connect } from "react-redux"
import "./styles.css"
import CompanyCard from "./CompanyCard"
import Slider from "react-slick"
import { baseUrlImg } from "../../../config/api"
import { Button } from "antd"

const Card = ({ imageUrl, Title, Info }) => (
  <div className="SDHome--Cards--Card">
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img src={imageUrl} />
    <h2>{Title}</h2>
    <p>{Info}</p>
  </div>
)

const recommandation = [
  {
    category: "Console e Giochi",
    company: "Nintendo",
    service: "A partire da 10,00€",
    img: "background/Nintendo.jpeg",
  },
  {
    category: "Console e Giochi",
    company: "XBox",
    service: "A partire da 10,00€",
    img: "background/xbox-gamepass.jpeg",
  },
  {
    category: "Crypto",
    company: "Binance",
    service: "A partire da 10,00€",
    img: "background/binance.jpeg",
  },
  {
    category: "Ricariche Conti Gioco",
    company: "Betflag",
    service: "A partire da 10,00€",
    img: "background/betflag.jpeg",
  },
  {
    category: "Ricariche Conti Gioco",
    company: "Poker Stars",
    service: "A partire da 10,00€",
    img: "background/pokerstars.jpeg",
  },
  {
    category: "Console e Giochi",
    company: "Roblox",
    service: "A partire da 10,00€",
    img: "background/roblox.jpeg",
  },
]

const topServices = [
  {
    category: "Ricariche Conti Gioco",
    company: "Poker Stars",
    service: "A partire da 10,00€",
    img: "background/pokerstars.jpeg",
  },
  {
    category: "Console e Giochi",
    company: "Steam",
    service: "A partire da 10,00€",
    img: "background/steam.jpeg",
  },
  {
    category: "Console e Giochi",
    company: "Roblox",
    service: "A partire da 10,00€",
    img: "background/roblox.jpeg",
  },
  {
    category: "Ricariche Conti Gioco",
    company: "Betflag",
    service: "A partire da 10,00€",
    img: "background/betflag.jpeg",
  },
  {
    category: "Crypto",
    company: "Binance",
    service: "A partire da 10,00€",
    img: "background/binance.jpeg",
  },
  {
    category: "Console e Giochi",
    company: "XBox",
    service: "A partire da 10,00€",
    img: "background/xbox-gamepass.jpeg",
  },
]
class StaticDefaultHomePage extends React.Component {
  render() {
    const { skinExtras } = this.props
    const skinLink = skinExtras.link3 ? skinExtras.link3.replace("https://", "") : ""
    const SkinName = skinExtras.name || ""

    const settings = {
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        // {
        //   breakpoint: 480,
        //   settings: {
        //     slidesToShow: 1,
        //   },
        // },
      ],
    }

    return (
      <div className="Container animated fadeIn">
        <div className="SDHome">
          <div className="banner">
            <div className="banner-title">
              <span>Valore aggiunto per la tua attività</span>
              <span>
                Il Tuo Partner per l'Innovazione Digitale e il Successo del Tuo
                Business
              </span>
              <Button>Richiedi Informazioni</Button>
            </div>
            <img src={`${baseUrlImg}/uncategorized/static-banner.png`} />{" "}
          </div>
          <div className="SDHome--Header">
            <span>
              <span>{`${SkinName}`}</span> <span>SERVIZI</span>
            </span>
            <span>
              La nostra proposta di affiliazione prevede esclusivamente i costi di
              start-up, in cui sono compresi gli arredi per l’allestimento del punto
              vendita, le attrezzature tecniche necessarie allo svolgimento
              dell’attività e il materiale di marketing.
            </span>
          </div>
          <div className="company-cards">
            <h3>Raccomandati per te</h3>
            <Slider {...settings} autoplaySpeed={2000}>
              {recommandation.map(({ img, category, company, service }, index) => (
                <div id={index}>
                  <CompanyCard
                    img={img}
                    category={category}
                    company={company}
                    service={service}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="company-cards">
            <h3>Top Servizi</h3>
            <Slider {...settings} autoplay={false}>
              {topServices.map(({ img, category, company, service }, index) => (
                <div id={index}>
                  <CompanyCard
                    img={img}
                    category={category}
                    company={company}
                    service={service}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="banner">
            <img src={`${baseUrlImg}/uncategorized/static-banner-1.png`} />{" "}
          </div>

          <div className="SDHome--Cards">
            {/* <Card
              Title={"SERVIZI POSTALI"}
              Info={`Con ${
                skinLink || ""
              } puoi pagare in totale sicurezza tutti i bollettini postali, MAV, RAV, Bollo
              Auto, F24, PagoPa, etc. Al termine dell’operazione sarà rilasciata una ricevuta
              dell’avvenuto pagamento.`}
              imageUrl={images["SER_POS"]}
            /> */}
            {/* <Card
              Title={"RICARICHE CELLULARI"}
              Info={`Ricarica il tuo cellulare nel ${skinLink} più vicino a te o anche direttamente dal tuo conto
              online. Eroghiamo tutti i tagli di ricarica dei gestori di telefonia mobile tradizionali.
              Bastano un paio di click per eseguire queste operazioni, comunicare il gestore e
              l’importo.`}
              imageUrl={images["RIC_CEL"]}
            /> */}
            {/* <Card
              Title={"RICARICHE TELEVISIONI DIGITALI"}
              Info={`Nei punti ${SkinName} si possono ricaricare le tessere Sky, Sat+, Dazn, Mediaset Premium. Per le piattaforme che distribuiscono via internet, film, serie tv e altri contenuti multimendiali, come Netlix, Prime Video, etc, offriamo la possibilità di ricariche dei conti.`}
              imageUrl={images["RIC_DIG"]}
            /> */}

            {/* <Card
              Title={"PRENOTAZIONE BIGLIETTI VOLI"}
              Info={`Grazie al nuovo servizio offerto dai support ${SkinName} si creano nuove opportunità di business nella nostra piattaforma. Il servizio che consente alle nostre agenzie la possibilità di proporre alla clientela la prenotazione dei voli nazionali ed internazionali. Questi servizi travel amplieranno l’offerta alla clientela incrementando così i guadagni e migliorando la visibilità del vostro punto`}
              imageUrl={images["PRE_VOL"]}
            />
            <Card
              Title={"PRENOTAZIONE BIGLIETTI TRENI"}
              Info={`Grazie al nuovo servizio offerto dai support ${SkinName} si creano nuove opportunità di business nella nostra piattaforma. Il servizio consente alle nostre agenzie la possibilità di proporre alla clientela la prenotazione treni, bus, aerei, nazionali ed internazionali. Questi servizi travel amplieranno l’offerta alla clientela incrementando così i guadagni e migliorando la visibilità del vostro punto.`}
              imageUrl={images["PRE_TRE"]}
            />
            <Card
              Title={"PRENOTAZIONE BIGLIETTI"}
              Info={`Nei ${skinLink} è attivo il servizio biglietteria su prenotazione.`}
              imageUrl={images["PRE_EVE"]}
            /> */}

            {/* <Card
              Title={"ACQUISTI ONLINE"}
              Info={`I Vostri clienti possono prenotare qualunque prodotto online e pagare direttamente nelle agenzie dove potranno ritirare il prodotto quando più comodo per loro. Il servizio è usufruibile su qualsiasi sito e-commerce online con la possibilità di guadagnare una commissione su ogni prenotazione.`}
              imageUrl={images["ACQ_ONL"]}
            /> */}

            {/* <Card
              Title={"NOLEGGIO AUTO"}
              Info={`Con il servizio puoi offrire alla tua clientela, noleggio auto a lungo termine, moto o veicoli commerciali. Il canone include RCA, Bollo, assicurazione furto e incendio, copertura danni al veicolo, manutenzione ordinaria e straordinaria, traino e soccorso stradale H24 e gestione sinistri. Scopri tutti i veicoli disponibili tra automobili, motorini, moto e veicoli commerciali: le proposte hanno una durata da 12 a 60 mesi, i canoni proposti dai nostri broker, sono calcolati con anticipo e senza anticipo e sono tra i più vantaggiosi offerti oggi dal mercato. Richiedi un preventivo personalizzato per i tuoi clienti guadagnando alla chiusura del contratto`}
              imageUrl={images["GIF_CAR"]}
            /> */}
            {/* <Card
              Title={"SPEDIZIONE"}
              Info={`Servizio flessibile e diversificato in grado di soddisfare le esigenze distributive dei clienti più esigenti: dalla consegna di diverse tipologie di spedizioni, alla fornitura di servizi logistici di supporto alla movimentazione ed alla distribuzione delle merci. Un’ampia e articolata gamma di servizi consente ai clienti di avvalersi di un unico interlocutore per tutte le esigenze di supporto logistico e distributivo in Italia, in Europa e nel mondo.`}
              imageUrl={images["SPE_DIZ"]}
            /> */}
            {/* <Card
              Title={"VISURE"}
              Info={`Scarica in pochi secondi Visure e Certificati rilasciati dagli Uffici Pubblici, accedi a Camera di
              Commercio, Catasto, Conservatoria, Tribunale, Anagrafe e Pra. Potrai chiedere qualsiasi
              documento in modo Veloce, Semplice e Sicuro.`}
              imageUrl={images["CRY_VAL"]}
            /> */}
            {/* <Card
              Title={"AVVOCATO ONLINE"}
              Info={`Alla gamma dei servizi offerti da ${SkinName}, si aggiunge la Consulenza Professionale prestata 
              da un qualificato network di professionisti su scala nazionale. È possibile prenotare online una prima 
              consulenza gratuita in materia Legale, Finanziaria, Fiscale/Tributaria, Contabile, Societaria, Disciplina del lavoro. 
              Richiedi un preventivo personalizzato per i tuoi clienti guadagnando alla chiusura del contratto.`}
              imageUrl={images["ALT_SER"]}
            /> */}
            {/* <Card
              Title={"RICARICHE PVR"}
              Info={`Ricaricare somme di denaro su un conto gioco, a prescindere dal bookmaker sul quale si è iscritti, è molto semplice. Si potranno effettuare le ricariche dei conti gioco dei più importanti siti di giochi e scommesse online. Al termine dell’operazione, dietro pagamento in contanti al punto vendita, sarà rilasciata una ricevuta con il codice voucher per eseguire la ricarica del tuo conto gioco.`}
              imageUrl={images["RIC_PVR"]}
            /> */}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  skinExtras: state.auth.skinExtras,
})
export default connect(mapStateToProps)(StaticDefaultHomePage)
