import { notification } from "antd"
import { unSubscribeSocketUser, unSubscribeSocketSupport } from "config/socket"
import React from "react"

// export const endpoint = "http://127.0.0.1:8000/api"
export const endpoint = window.location?.href.includes("staging")
  ? "https://new-staging.bpoint.store/api"
  : "https://mrbo.mrwallet.app/api"
const apiUrl = `${endpoint}`

export const baseUrlImg = endpoint.replace("/api", "/gallery")

export const getToken = () => {
  const value = localStorage.getItem("accountDataB")
  const keys = JSON.parse(value)
  return `Bearer ${keys?.token}`
}

export const PROJECT_ID = "e89edb73"

const hasCode = (error, status) => {
  if (
    error?.response?.status === parseInt(status) ||
    error.error?.response?.status === parseInt(status) ||
    error?.response?.status === parseInt(status)
  ) {
    return true
  } else {
    return false
  }
}
export const handleError = (error) => {
  // console.log("error handler", error, { error });
  if (hasCode(error, 401)) {
    //loged out
    unSubscribeSocketUser(
      JSON.parse(localStorage.getItem("accountDataB")).profile.id
    )
    if (
      JSON.parse(localStorage.getItem("accountDataB")).profile.role.name ===
      "support"
    ) {
      unSubscribeSocketSupport()
    }
    localStorage.setItem("accountDataB", null)
    window.store.dispatch({ type: "SET_UNAUTHORIZATION" })
  } else if (error?.message === "Network Error") {
    notification["error"]({
      key: "networkErr",
      message: `Non connesso a Internet`,
      icon: <i className="fal fa-wifi-slash"></i>,
      duration: 0,
    })
    // unSubscribeSocketUser(JSON.parse(localStorage.getItem("accountDataB")).profile.id);
    // if (JSON.parse(localStorage.getItem("accountDataB")).profile.role.name === "support") {
    //   unSubscribeSocketSupport();
    // }
    // localStorage.setItem("accountDataB", null);
    // window.store.dispatch({ type: "SET_UNAUTHORIZATION" });
  } else if (hasCode(error, 445)) {
    //skin id wrong
  } else if (hasCode(error, 440)) {
    localStorage.setItem("accountDataB", null)
    window.store.dispatch({ type: "SET_UNAUTHORIZATION" })
  } else if (hasCode(error, 429)) {
    // console.log("to many request");
  } else if (hasCode(error, 403)) {
    //forbiden , kryesisht > prenotazione
    notification["warning"]({
      message: `Forbidden`,
    })
  } else if (hasCode(error, 514)) {
    // Kur sitemi do jete ne under construction
    window.location.href = "#/comming-soon"
  } else if (hasCode(error, 422)) {
    const responseData = error?.response?.data
    const errorDetails = responseData?.errors
      ? responseData?.errors
      : responseData?.error
      ? responseData?.error
      : responseData?.message 

    console.log(error.response)

    if (errorDetails) {
      let errorMessages

     
      if (typeof errorDetails === "object") {
        errorMessages = Object.keys(errorDetails)
          .map((field) => `${field}: ${errorDetails[field].join(", ")}`)
          .join("\n")
      } else if (typeof errorDetails === "string") {
        errorMessages = errorDetails 
      }

      notification["error"]({
        message: "Error",
        description: errorMessages,
        placement: window.innerWidth <= 1024 ? "topRight" : "bottomRight",
        duration: 4,
      })
    }
  } else {
    notification["error"]({
      message: error?.response?.data?.message || "Error",
      description: error?.response?.data?.errors
        ? Object.values(error.response.data.errors)
        : error?.response?.data?.error,
      placement: window.innerWidth <= 1024 ? "topRight" : "bottomRight",
      duration: 4,
    })
  }
  return Promise.reject(error)
}

export let skin = {
  skin_id: localStorage.getItem("skin_id") || "2",
}

window.apiUrl = apiUrl

export default {
  endpoint,
  baseUrl: apiUrl,
  baseUrlImg,
  headers: {
    //  "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
}
