import "./style.css"
import React, { useState, useEffect } from "react"
import { UserInfoBar } from "shared-componentsMobile"
import Servizi from "./Servizi"
import { isEqual } from "lodash"
import { AuthActions, MainActions } from "redux-store/models"
import { connect } from "react-redux"
import ServicesForms from "./ServicesForms"
import { similarity, isSepafinSelector } from "utils/HelperFunc"
import NewServizi from "./NewServizi"

export const serviceIcons = {
  53: "fa-gamepad",
  61: "fa-credit-card",
  173: "fa-signature",
  253: "fa-file-alt",
  42: "fa-mobile",
  37: "fa-wallet",
  38: "fa-bitcoin-sign",
  39: "fa-gift",
}

const Card = ({ setTab, tab, id, name, icon, setSearch, isSepafin }) => {
  const pagaamentiCategory = isSepafin ? "SPRDPST" : "PRDPST"
  return (
    <div
      onClick={() => {
        setSearch("")
        setTab(id)
        // if (id !== "0" && id.includes(pagaamentiCategory)) {
        //   const el = document.querySelector(`#${pagaamentiCategory}`);
        //   if (el) el.click();
        // }
      }}
      id={`tab${id}`}
      className={"serviziFilter--cards__item" + (isEqual(tab, id) ? " active" : "")}
    >
      <i className={`fal ${icon}`}></i>
      <span>{name}</span>
    </div>
  )
}

const Dashboard = (props) => {
  const {
    getFavorites,
    favorites,
    toggleFavorite,
    accountInfo,
    getServices,
    setPrenotaBolletino,
    scannedBarcode,
    companyServices,
    service_s,
    servicesTree,
    activeTab,
  } = props
  useEffect(() => {
    getFavorites()
    // getServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSepaUser = accountInfo.profile.sepafin_active

  const [tab, setTab] = useState("0")
  useEffect(() => {
    const el = document.querySelector(
      ".mobileServices--panel .mobileServices--header"
    )
    if (el) el.click()
  }, [tab])

  const [serviceSearched, setSearch] = useState("")
  const [activeCategory, setCategory] = useState(null)
  const [activeService, setService] = useState(null)

  useEffect(() => {
    const searchWord = serviceSearched.toString().toLowerCase().trim()
    if (
      similarity(searchWord, "cafe") >= 0.8 ||
      similarity(searchWord, "coffee") >= 0.8
    ) {
      setTab("8")
      setService(null)
    }
  }, [serviceSearched])

  useEffect(() => {
    if (scannedBarcode) {
      console.log("dashboard", scannedBarcode)

      let parsedData
      try {
        parsedData = JSON.parse(scannedBarcode)
        if (parsedData && parsedData.service_id) {
          console.log("set active", parsedData.category_id)
          setCategory(parsedData.category_id)
        }
      } catch (err) {
        console.error("Failder to parse JSON", err)
      }
    } else {
      setCategory(props.activeCategory)
    }
  }, [props.activeCategory, scannedBarcode])

  useEffect(() => {
    setService(props.activeService)
  }, [props.activeService])

  const setServ = (ser) => {
    setService(ser)
    props.setActiveService && props.setActiveService(ser)
  }

  return (
    <section className="mobileWrapper">
      <UserInfoBar />
      {service_s?.length !== 0 || companyServices?.length !== 0 ? (
        <ServicesForms
          service_s={service_s}
          companyServices={companyServices}
          setService={(val) =>
            val === null && setPrenotaBolletino
              ? setPrenotaBolletino(false)
              : setServ(val)
          }
          services={props.services}
          activeCategory={activeCategory}
          activeService={activeService}
          favorites={favorites}
          toggleFavorite={toggleFavorite}
          accountInfo={accountInfo}
        />
      ) : (
        <React.Fragment>
          <div className="serviziFilter">
            <div className="serviziFilter--search">
              <input
                value={serviceSearched}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Cerca tra i servizi attivi"
              />
              <i className="fal fa-search" aria-hidden="true"></i>
            </div>
            <div className="serviziFilter--cards">
              <Card
                setSearch={setSearch}
                id={"fav"}
                icon="fa-star"
                tab={tab}
                setTab={setTab}
                name="Servizi Preferiti"
              />
              {/* <div
                onClick={() => {
                  window.location.hash = "products"
                }}
                className={"serviziFilter--cards__item"}
              >
                <i className={`fal fa-shopping-cart`}></i>
                <span>Shop</span>
              </div> */}
              {/* <Card id={"8"} setSearch={setSearch} icon="fa-coffee" tab={tab} setTab={setTab} name={`Mamanero Caffé`} />  */}
              <Card
                id={"0"}
                setSearch={setSearch}
                icon="fa-file-alt"
                tab={tab}
                setTab={setTab}
                name={`Tutti${"\n"}Servizi`}
              />
              {servicesTree
                ?.filter(
                  (service, index, self) =>
                    service.parent_id === null &&
                    service.name !== "Uncategorized" &&
                    index === self.findIndex((s) => s.name === service.name)
                )
                .map((service) => {
                  const icon = serviceIcons[service.id] || "fa-question-circle"
                  return (
                    <Card
                      key={service.id}
                      id={service.id}
                      setSearch={setSearch}
                      icon={icon}
                      tab={tab}
                      setTab={setTab}
                      name={service.name}
                    />
                  )
                })}
            </div>
          </div>
          <NewServizi tab={tab} activeTab={activeTab} setTab={setTab} />
        </React.Fragment>
      )}
    </section>
  )
}
const mstp = (state) => {
  const {
    main: { favorites, companyServices, servicesTree },
    auth: { accountInfo, scannedBarcode, service_s },
  } = state
  return {
    favorites,
    accountInfo,
    scannedBarcode,
    companyServices,
    service_s,
    servicesTree,
    isSepafin: isSepafinSelector(state),
  }
}

export default connect(mstp, { ...AuthActions, ...MainActions })(Dashboard)
